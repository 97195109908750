import { PRICING_COHORTS, FREE_TRIAL_TYPES } from '@busuu/pricing';
// Skeleton for price tier experiments
export const isInPricingExperiment = () => {
    return false;
};
export const getStandardPricingCohort = () => {
    return PRICING_COHORTS.STANDARD;
};
export const getFTReferralPricingCohort = () => {
    return PRICING_COHORTS.FREE_TRIAL_REFERRAL;
};
export const getFTBasicPricingCohort = () => {
    return PRICING_COHORTS.FREE_TRIAL_BASIC;
};
export const getFreeTrialPricingCohort = (trialType) => {
    switch (trialType) {
        case FREE_TRIAL_TYPES.REFERRAL:
            return getFTReferralPricingCohort();
        default:
            return getFTBasicPricingCohort();
    }
};
