import Utils from 'core-helpers/utils.js';

import {ERROR_UNKNOWN} from 'endpoint/api-requests-service.constants.js';

/**
 * Returns an Object from an Axios XHR Error
 * @param  {Axios XHR Error} error
 * @return {Object}
 */
export const decomposeAxiosError = (error = {}, defaultError = ERROR_UNKNOWN) => {
    let message = null;
    let applicationCode = null;

    const data = error.response ? error.response.data : null;

    try {
        if (Utils.isArray(data.errors)) {
            // eslint-disable-next-line prefer-destructuring
            message = data.errors[0];
        } else {
            message = data.errors || data.message;
        }
        applicationCode = data.applicationCode;
    } catch (e) {
        // Silently fail.
    }

    applicationCode = applicationCode || defaultError.applicationCode;
    message = message || defaultError.message;

    return {
        message,
        applicationCode,
    };
};

/**
 * Returns an object in the correct format for `decomposeAxiosError`
 * TODO: Align cross project so we don't need to decompose in individual functions and need to
 * pass this in when manually throwing errors.
 * @param {Object} error
 * @returns {Object}
 */
export const composeAxiosError = (error) => ({
    response: {
        data: error,
    },
});
