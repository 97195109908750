import Utils from 'core-helpers/utils.js';
import DOMUtilsService from 'core-services/dom-utils-service.js';
import {states, stateConfigs} from 'common/toaster.constants.js';

const AUTO_CLOSE = 4000;
const TOAST_HOLDER_CLASS = 'toaster';

let notifications = [];
let toastHolder;

/**
 * Creates a new notification element and returns it.
 * @param {'success'|'warn'|'error'} type
 * @param {String} value
 */
const createNotificationElement = (type, value) => {
    const config = stateConfigs[type];

    if (config) {
        const notificationElement = DOMUtilsService.createDOMElement({
            type: 'div',
            class: `toaster__notification ${config.className}`,
        });

        const notificationIcon = DOMUtilsService.createDOMElement({
            type: 'span',
            class: 'icon icon--light toaster__notification_icon',
        });

        const notificationText = DOMUtilsService.createDOMElement({
            type: 'span',
            text: value,
        });

        notificationElement.appendChild(notificationIcon);
        notificationElement.appendChild(notificationText);
        toastHolder.appendChild(notificationElement);

        return notificationElement;
    }

    return null;
};

/**
 * Hides the notification after a time
 * @param {Number} notificationKey
 */
const hide = (notificationKey) => {
    const notification = notifications.find(({key}) => key === notificationKey);

    if (notification) {
        Utils.addClass(notification.element, 'toaster__notification--hide');

        const removeNotificationElement = () => {
            toastHolder.removeChild(notification.element);
            notifications = notifications.filter(({key}) => key !== notificationKey);
        };

        window.setTimeout(removeNotificationElement, 300);
    }
};

/**
 * Shows a notification
 * @param {'success'|'warn'|'error'} type
 * @param {String} value
 */
const show = (type, value) => {
    const notificationKey = Utils.generateUUID();
    const notificationElement = createNotificationElement(type, value);

    if (notificationElement) {
        notifications.push({
            key: notificationKey,
            element: notificationElement,
            type,
            value,
        });

        window.setTimeout(hide.bind(null, notificationKey), AUTO_CLOSE);
    }
};

/**
 * Shows a notifification with type `success`
 * @param {String} value
 */
const success = (value = '') => {
    show(states.SUCCESS, value);
};

/**
 * Shows a notifification with type `warn`
 * @param {String} value
 */
const warn = (value = '') => {
    show(states.WARNING, value);
};

/**
 * Shows a notifification with type `error`
 * @param {String} value
 */
const error = (value = '') => {
    show(states.ERROR, value);
};

/**
 * Fetches or creates the toaster holder and returns it
 * @returns {Element}
 */
const getToasterElement = () => {
    let toaster = Utils.getElementByClass(TOAST_HOLDER_CLASS);

    if (!toaster) {
        toaster = DOMUtilsService.createDOMElement({
            type: 'div',
            class: TOAST_HOLDER_CLASS,
        });

        document.body.appendChild(toaster);
    }

    return toaster;
};

const init = () => {
    toastHolder = getToasterElement();
};

init();

const ToasterService = {
    success,
    warn,
    error,
    show,
};

export default ToasterService;
