/**
 * Marketing reorder list of flags based on course popularity
 * Used on the flag modules within the main landing pages
 * N.B: hardcoded since backend returns a single order of courses
 * N.B 2: NL exists only on certain interfaces (for now)
 */
export const COURSES_LANGS_BY_INTERFACE_LANGS = {
    en: ['en', 'es', 'ja', 'fr', 'de', 'ko', 'it', 'ar', 'ru', 'zh', 'tr', 'pt', 'nl', 'pl'],
    es: ['en', 'fr', 'ja', 'it', 'ko', 'pt', 'de', 'es', 'zh', 'ru', 'ar', 'tr', 'pl'],
    fr: ['en', 'fr', 'es', 'ja', 'ar', 'de', 'it', 'ko', 'ru', 'pt', 'tr', 'nl', 'zh', 'pl'],
    de: ['en', 'es', 'de', 'ja', 'fr', 'it', 'ru', 'tr', 'pl', 'ar', 'ko', 'pt', 'nl', 'zh'],
    us: ['es', 'ja', 'en', 'fr', 'de', 'it', 'ko', 'nl', 'ru', 'zh', 'pt', 'ar', 'tr', 'pl'], // For Chegg US campaign
    pt: ['en', 'es', 'fr', 'ja', 'it', 'ko', 'de', 'pt', 'ru', 'zh', 'ar', 'tr', 'pl'],
    zh: ['en', 'ja', 'fr', 'es', 'de', 'ru', 'it', 'ko', 'pt', 'zh', 'ar', 'pl', 'tr'],
};

/**
 * List of countries where we can show the US flag
 * instead of the British flag and reorder the flags
 */
export const COUNTRY_TO_SHOW_US_FLAG = 'US';
