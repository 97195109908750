/* eslint-disable no-plusplus */
import debounce from 'lodash/debounce';
import Utils from 'core-helpers/utils.js';
import BreakPointDetection from 'helpers/breakpoint-detection.js';
import PubSub from 'pubsub-js/src/pubsub.js';

/**
 * Slider with infinite loop
 * To ensure an infinite loop we're cloning all the visible elements and append
 * them into the container. Doing this we ensure that the last view is the same
 * as the first view.
 * Everytime the user triggers an action that will cause to go to the next or previous slide
 * a transition is triggered. On the last view, we translate the entire content
 * to the initial value without any transition
 */
class Slider {
    /**
     * Creates a slider
     * @param {HTMLElement} sliderElement
     * @param {Object} opts
     */
    constructor(sliderElement, opts = {}) {
        this.options = {
            slidesContainerClass: 'js-slide-container', // Container of the slides
            slideClass: 'js-slide', // Class for slides
            sliderScrollContainer: 'js-slider-scroll', // Container of the scroll
            nextClass: 'js-next',
            previousClass: 'js-prev',
            transitionTime: 200, // Time of the transition espressed in ms
            mobileBreakpoint: 2, // z-index of the BreakpointDetector element,
            ...opts,
        };

        // Method binding
        this.cloneElements = this.cloneElements.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
        this.getVisibleElements = this.getVisibleElements.bind(this);
        this.render = this.render.bind(this);
        this.setCurrent = this.setCurrent.bind(this);
        this.update = this.update.bind(this);
        this.initView = this.initView.bind(this);
        this.removeClonedElements = this.removeClonedElements.bind(this);
        this.setDisabledAttributeOnActions = this.setDisabledAttributeOnActions.bind(this);
        this.initDOMElements = this.initDOMElements.bind(this);
        this.initUIListeners = this.initUIListeners.bind(this);
        this.refreshSlidesElements = this.refreshSlidesElements.bind(this);

        // Listen for changes on the breakpoint and reinitialise the view
        PubSub.subscribe('BREAKPOINT_DETECTION__CHANGED', this.initView);

        // Init
        this.initDOMElements(this.options, sliderElement);
        this.initUIListeners();
        this.initView();
    }

    /**
     * Returns if it's mobile view
     */
    isMobileView() {
        return Boolean(BreakPointDetection.getState() <= this.options.mobileBreakpoint);
    }

    /**
     * Inits the listeners on actions and resize
     */
    initUIListeners() {
        this.nextButton.addEventListener('click', this.nextSlide);
        this.previousButton.addEventListener('click', this.previousSlide);
        window.addEventListener(
            'resize',
            debounce(() => this.initView()),
            250
        );
    }

    /**
     * Init the DOM Elements of the slider
     * @param {Object} options
     * @param {DOMElement} sliderElement
     */
    initDOMElements(options, sliderElement) {
        this.sliderElement = sliderElement;
        this.sliderContainer = Utils.getElementByClass(options.slidesContainerClass, sliderElement);
        this.sliderScrollContainer = Utils.getElementByClass(options.sliderScrollContainer, sliderElement);
        this.slides = Utils.getElementsByClass(options.slideClass, sliderElement);
        this.nextButton = Utils.getElementByClass(options.nextClass, sliderElement);
        this.previousButton = Utils.getElementByClass(options.previousClass, sliderElement);
    }

    /**
     * Inits the view based on the current viewport and triggers a render
     * It's called everytime the window is resized
     */
    initView() {
        this.removeClonedElements();
        this.refreshSlidesElements();

        if (this.isMobileView()) {
            this.setCurrent(0);
            this.render();
            return false;
        }

        const numberOfSlides = this.slides.length;
        this.slideWidth = this.slides[0].offsetWidth;
        this.frameWidth = this.sliderContainer.offsetWidth;

        this.visibleElements = this.getVisibleElements();
        const isNotScrollable = numberOfSlides <= this.visibleElements;

        if (isNotScrollable) {
            this.setDisabledAttributeOnActions(true);
            this.setCurrent(0);
        } else {
            this.cloneElements();
            this.setCurrent(this.visibleElements);
            this.setDisabledAttributeOnActions(false);
        }

        this.sliderScrollContainer.scrollLeft = 0;
        this.render();
    }

    /**
     * Remove all the cloned elements from the DOM;
     * We need to clean the clones on every resize
     */
    removeClonedElements() {
        const clonedElements = Utils.getElementsByClass('js-cloned', this.sliderContainer);
        clonedElements.forEach((item) => this.sliderContainer.removeChild(item));
    }

    /**
     * Refresh slides
     */
    refreshSlidesElements() {
        this.slides = Utils.getElementsByClass(this.options.slideClass, this.sliderElement);
    }

    /**
     * Clones the initial and last elements and insert them into the DOM
     */
    cloneElements() {
        const firstSlides = this.slides.slice(0, this.visibleElements);
        const lastSlides = this.slides.slice(-this.visibleElements);

        // Given an array of slides it creates a DocumentFragment with them
        const createSlidesFragment = (slides) => {
            const newFrag = document.createDocumentFragment();

            for (let i = 0; i < slides.length; i++) {
                newFrag.appendChild(slides[i]);
            }

            return newFrag;
        };

        // Clones the item and adds a class js-cloned to it
        const clone = (item) => {
            const clonedItem = item.cloneNode(true);
            clonedItem.classList.add('js-cloned');
            return clonedItem;
        };

        // Clone the elements
        const clonedFirstSlides = firstSlides.map(clone);
        const clonedLastSlides = lastSlides.map(clone);

        // Create Fragments
        const firstSlidesFragment = createSlidesFragment(clonedFirstSlides);
        const lastSlidesFragment = createSlidesFragment(clonedLastSlides);

        // Add fragments
        this.sliderContainer.appendChild(firstSlidesFragment);
        this.sliderContainer.insertBefore(lastSlidesFragment, this.sliderContainer.firstChild);

        // Update the slides after the cloning
        this.refreshSlidesElements();
    }

    /**
     * Sets the disabled attribute on actions
     */
    setDisabledAttributeOnActions(disabled) {
        if (disabled) {
            this.nextButton.setAttribute('disabled', disabled);
            this.previousButton.setAttribute('disabled', disabled);
        } else {
            this.nextButton.removeAttribute('disabled');
            this.previousButton.removeAttribute('disabled');
        }
    }

    /**
     * Returns the maximum amount of visible elements in the current view
     */
    getVisibleElements() {
        return Math.round(this.frameWidth / this.slideWidth);
    }

    /**
     * Gets the width of a slide and performs the transition
     * @param {Boolean} transition
     */
    render(performTransition) {
        const transitionTime = this.options.transitionTime / 1000;
        const totalToTranslate = this.currentIndex * this.slideWidth;
        const translateValue = `translateX(-${totalToTranslate}px`;

        let transitionValue = `${transitionTime}s transform ease-in`;
        if (!performTransition) {
            transitionValue = 'auto';
        }

        this.sliderContainer.style.transition = transitionValue;
        this.sliderContainer.style.transform = translateValue;
    }

    /**
     * Sets the current index of the slide
     * @param {Integer} value
     */
    setCurrent(value) {
        this.currentIndex = value;
    }

    /**
     * Sets the next index and update the view.
     * @param {Integer} nextIndex
     * @param {Integer} initialIndex
     * @param {Boolean} isLimit
     */
    update(nextIndex, initialIndex, isLimit) {
        this.setDisabledAttributeOnActions(true);
        this.setCurrent(nextIndex);
        this.render(true);

        setTimeout(() => {
            if (isLimit) {
                this.setCurrent(initialIndex);
                this.render();
            }
            this.setDisabledAttributeOnActions(false);
        }, this.options.transitionTime);
    }

    /**
     * Goes to the previous slide of the slider
     * 1. Since we clone on the right and on the left the number of items equals so the visible elements,
     *    we have to multiply by 2 to reset to the initial view
     */
    previousSlide() {
        const nextIndex = this.currentIndex - 1;
        const isLastView = Boolean(nextIndex === 0);
        const initialIndex = this.slides.length - this.visibleElements * 2; // 1

        this.update(nextIndex, initialIndex, isLastView);
    }

    /**
     * Goes to the next slide of the slider
     */
    nextSlide() {
        const nextIndex = this.currentIndex + 1;
        const initialIndex = this.visibleElements;
        const latestVisibleElement = this.currentIndex + this.visibleElements;
        const isLastView = Boolean(latestVisibleElement >= this.slides.length - 1);

        this.update(nextIndex, initialIndex, isLastView);
    }
}

export default Slider;
