import RunningExperimentsService from 'experiments/experiments-service.ts';
import {
    ALL_USER_FREE_TRIAL,
    ALL_USER_FREE_TRIAL_VARIATION,
    ALL_USER_FREE_TRIAL_VARIATION_NO_BANNER,
} from 'experiments/experiments-service.constants.ts';

const isInOneDayFTVariant = () => {
    return RunningExperimentsService.isInExperimentBucket(ALL_USER_FREE_TRIAL, ALL_USER_FREE_TRIAL_VARIATION);
};

const isInNoBannerExperiment = () => {
    return RunningExperimentsService.isInExperimentBucket(ALL_USER_FREE_TRIAL, ALL_USER_FREE_TRIAL_VARIATION_NO_BANNER);
};

const isInExperiment = () => {
    return isInOneDayFTVariant() || isInNoBannerExperiment();
};

const FreeTrialExperiment = {
    isInNoBannerExperiment,
    isInOneDayFTVariant,
    isInExperiment,
};

export default FreeTrialExperiment;
