import Utils from 'core-helpers/utils.js';
import TimeHelper from 'core-helpers/time-helper.js';

import BreakPointDetection from 'helpers/breakpoint-detection.js';
import TranslationsService from 'common/translations.service.ts';
import PromotionService from 'promotion/promotion-service.js';

const TWENTY_FOUR_HOURS_SECONDS = 86400;
const FORTY_EIGHT_HOURS_SECONDS = 172800;

const BANNER_TEXT_CLASS = 'js-banner-text';
const BANNER_TEXT_MOBILE_CLASS = 'js-banner-text-mobile';
const BANNER_COUNTDOWN_CLASS = 'promotion-banner--countdown';
const BANNER_ACTIVE_CLASS = 'promotion-banner--active';

class PromotionBanner {
    constructor(promotionBanner, planDuration) {
        this.promotionBanner = promotionBanner;

        this.countdownInterval = null;
        this.bannerVisible = false;

        if (this.promotionBanner) {
            this.initBanner(planDuration);
        }
    }

    initBanner(planDuration) {
        const onSuccess = (promotion) => {
            const displayCountdown = PromotionService.canCountdownDisplay(promotion, planDuration);
            const displayDiscount = PromotionService.canDiscountDisplay(promotion, planDuration);

            if (displayCountdown) {
                this.setBannerCountdown(promotion);
                this.setBannerText(BANNER_TEXT_MOBILE_CLASS, promotion);
            }

            if (displayDiscount && !displayCountdown) {
                this.setBannerText(BANNER_TEXT_CLASS, promotion);
                this.setBannerText(BANNER_TEXT_MOBILE_CLASS, promotion);
                this.show();
            }
        };

        PromotionService.getPromotion()
            .then(onSuccess)
            .catch(() => {});
    }

    setBannerText(textElementClass, {value}) {
        const textElement = Utils.getElementByClass(textElementClass, this.promotionBanner);
        const basicTranslation = TranslationsService.getAndReplaceTranslationMulti('X%_OFF_PREMIUM_PLUS', [value]);

        Utils.setText(textElement, basicTranslation);
    }

    setBannerCountdown({value, expiration_date: expirationDate}) {
        const textElement = Utils.getElementByClass(BANNER_TEXT_CLASS, this.promotionBanner);
        let timeDifference = TimeHelper.calculateTimeDifference(expirationDate);
        let prevContent;

        this.countdownInterval = setInterval(() => {
            if (timeDifference > 1) {
                timeDifference -= 1;

                const onMobile = BreakPointDetection.getState() < 2;
                const nextContent = this.getCountdownHTML(value, timeDifference);

                if (nextContent !== prevContent && !onMobile) {
                    textElement.innerHTML = nextContent;
                }

                prevContent = nextContent;

                // Do this at the end to ensure that we don't get an empty styled div on the page
                if (!this.bannerVisible) {
                    this.show();
                }
            } else {
                this.hide();
            }
        }, 1000);

        Utils.addClass(this.promotionBanner, BANNER_COUNTDOWN_CLASS);
    }

    getCountdownHTML(value, timeDifference) {
        if (timeDifference < TWENTY_FOUR_HOURS_SECONDS) {
            return this.getLessThan24HoursHTML(value, timeDifference);
        }

        if (timeDifference < FORTY_EIGHT_HOURS_SECONDS) {
            return TranslationsService.getAndReplaceTranslationMulti('X%_OFF_PREMIUM_PLUS_EXPIRES_TOMORROW', [value]);
        }

        return TranslationsService.getAndReplaceTranslationMulti('X%_OFF_PREMIUM_PLUS_EXPIRES_ENDS_SOON', [value]);
    }

    getLessThan24HoursHTML(value, timeDifference) {
        const readableTime = TimeHelper.secsToHumanReadable(timeDifference);
        const [hours, mins] = readableTime.split(':');

        const countdownCard = `
            <span class="promotion-banner-card-wrapper">
                ${[hours, mins]
                    .map(
                        (unit) => `
                    <span class="promotion-banner-card">
                        ${unit
                            .split('')
                            .map(
                                (val) => `
                            <span class="promotion-banner-card__inner">
                                ${val}
                            </span>
                        `
                            )
                            .join('')}
                    </span>
                `
                    )
                    .join('')}
            </span>
        `;

        return TranslationsService.getAndReplaceTranslationMulti('X%_OFF_PREMIUM_PLUS_EXPIRES_IN_X', [
            value,
            countdownCard,
        ]);
    }

    show() {
        Utils.addClass(this.promotionBanner, BANNER_ACTIVE_CLASS);
    }

    hide() {
        Utils.removeClass(this.promotionBanner, BANNER_ACTIVE_CLASS);
    }
}

export default PromotionBanner;
