import ExceptionService from 'core-services/exceptions/exception-service.js';
import ThirdPartyService from 'core-services/third-party-service.js';

/**
 * Google Tag Manager
 */
var GTMScript = (function (root, doc, undefined) {

    var logError = function (message, data) {
        ExceptionService.handle('warning', { filename, message, data });
    };

    var isVendorDisabled = function () {
        return ThirdPartyService.isVendorDisabled();
    };

    var loadGTM = function () {

        var gtmId = doc.body.getAttribute('data-gtm-id');
        if (!gtmId) {
            logError('data-gtm-id attribute not found');
            return;
        }

        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                'event': 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', gtmId);
    };

    var load = function () {
        if (isVendorDisabled()) {
            return false;
        }

        root.dataLayer = root.dataLayer || [];
        root.addEventListener('load', loadGTM);

    };

    /**
     * Private vars
     */
    var filename = 'script-gtm.js';

    return {
        load: load
    };

})(window, document);

export default GTMScript;
