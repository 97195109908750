import APIRequestsService from 'endpoint/api-requests-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import endpoints from 'endpoint/endpoint-service.js';
import Utils from 'core-helpers/utils.js';
import {decomposeAxiosError, composeAxiosError} from 'endpoint/api-requests-service.helpers.js';
import {ERROR_BAD_RESPONSE_FORMAT} from 'endpoint/api-requests-service.constants.js';

// Private vars
const filename = 'promotion-service.js';

const logError = (message, data) => {
    ExceptionService.handle('warning', {filename, message, data});
};

/**
 * Countdown will trigger only if promotion
 * is one of the following types. (key: type)
 * @type {Array}
 */
const COUNTDOWN_ACCEPTED_TYPES = ['cart', 'streak', 'campaign_segment'];

const getInterfaceLang = () => {
    return window.BUSUU_GLOBALS.LANG_INTERFACE;
};

/**
 * Indicates whether or not a promotion is present
 * in the backend response. Will check
 * for all mandatory properties to be present.
 * @param  {Object}  data
 * @return {Boolean}
 */
const hasPromotion = (data) => {
    const mandatoryProps = ['key', 'type', 'value'];
    return Boolean(mandatoryProps.every((item) => data[item]));
};

/**
 * Returns whether or not a promotion countdown can
 * display based on the promotion data.
 * @param  {Object} promotion - promotion backend response
 * @param  {Number} planDuration
 * @return {Boolean}
 */
const canCountdownDisplay = (promotion, planDuration) => {
    // If promotion not present or inactive, return false
    if (!promotion || !promotion.active_promotion) {
        return false;
    }

    const isExpirationDateValid = Utils.isNumber(promotion.expiration_date);
    const typeAllowed = COUNTDOWN_ACCEPTED_TYPES.includes(promotion.type);
    const isInBillingPeriod = Boolean(promotion.billing_periods && promotion.billing_periods.includes(planDuration));
    return Boolean(isExpirationDateValid && typeAllowed && isInBillingPeriod);
};

/**
 * Returns whether or not a promotion discount can
 * display based on the promotion data.
 * @param  {Object} promotion - promotion backend response
 * @param  {Number} planDuration
 * @return {Boolean}
 */
const canDiscountDisplay = (promotion, planDuration) => {
    // If promotion not present or inactive, return false
    if (!promotion || !promotion.active_promotion) {
        return false;
    }

    const isExpirationDateValid = Utils.isNumber(promotion.expiration_date);
    const isInBillingPeriod = Boolean(promotion.billing_periods && promotion.billing_periods.includes(planDuration));
    return Boolean(isExpirationDateValid && isInBillingPeriod);
};

/**
 * Restructures the data recieved from the backend
 * to something more usable on our side
 * @param  {Object} data
 * @return {Object} restructured data
 */
const restructureData = (data) => {
    let object = Utils.deepClone(data);
    object = {...object.promotion};
    object.active_promotion = Boolean(data.active_promotion && hasPromotion(object));
    return object;
};

/**
 * Returns the promotion endpoint promise.
 * NB: Success doesn't mean a promotion is present.
 * @return {Promise}
 */
const getPromotion = () => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`getPromotion() error: ${applicationCode} - ${message}`, error);

        throw {
            type: applicationCode,
            message,
        };
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return restructureData(response.data);
    };

    const url = endpoints.generateEndpoint('promotion').replace('{interface_language}', getInterfaceLang());

    return APIRequestsService.get(url).then(onSuccess).catch(onError);
};

/**
 * Returns whether or not a promotion can be shown as buy x get x free
 * @param  {Number} percentDiscount
 * @param  {Number} planDuration
 * @return {Boolean}
 */
const canShowBuyXGetXFree = (percentDiscount, planDuration) => {
    const isDiscountValid = percentDiscount === 50;
    const isDurationValid = planDuration % 2 === 0;
    return isDiscountValid && isDurationValid;
};

const PromotionService = {
    getPromotion,
    canCountdownDisplay,
    canDiscountDisplay,
    canShowBuyXGetXFree,
};

export default PromotionService;
