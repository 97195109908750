import each from 'lodash/each';
import Utils from 'core-helpers/utils.js';
import BrowserStorageService from 'core-services/browser-storage-service.js';
import CookiesStorageService from 'core-services/cookies-storage-service.js';
import MarketingService from 'core-services/marketing-service.js';

/**
 * This service stores data across the webapp
 * or across pages in the homepage, in most of the cases, these
 * data are used to send events or to track the user.
 */

/**
 * Remove a given key
 * @param  {string} key
 */
const remove = (key) => {
    BrowserStorageService.remove(key);
};

/**
 * Registration Onboarding
 */
const storeRegistrationOnboarding = () => {
    BrowserStorageService.set('REGISTRATION_ONBOARDING');
};

/**
 * Payment page views
 */
const storePremiumPageView = () => {
    BrowserStorageService.set('PAGE_VIEWED_PREMIUM');
};

const storePurchasePageView = () => {
    BrowserStorageService.set('PAGE_VIEWED_PURCHASE');
};

/**
 * Payment discount
 */
const storeDiscountCoupon = (coupon) => {
    BrowserStorageService.set('DISCOUNT_COUPON', coupon);
};

const getDiscountCoupon = () => {
    return BrowserStorageService.get('DISCOUNT_COUPON');
};

const getQACaptchaToken = () => {
    return BrowserStorageService.get('CAPTCHA_TOKEN');
};

/**
 * Store plan infos in the browser on the current session.
 * These data will be used on /purchase page
 * to request backend APIs and to display price.
 * @param  {Object} plan
 */
const storeSelectedPlan = (plan) => {
    if (!Utils.isObject(plan)) {
        return false;
    }
    const _plan = JSON.stringify(plan);
    BrowserStorageService.set('SELECTED_PLAN', _plan);
};

/**
 * Returns the plan previously selected
 * @return {Object}
 */
const getSelectedPlan = () => {
    const planData = BrowserStorageService.get('SELECTED_PLAN');
    return planData ? JSON.parse(planData) : null;
};

/**
 * Clear all payment stored data
 */
const clearPaymentData = () => {
    // Remove page views
    remove('PAGE_VIEWED_PREMIUM');
    remove('PAGE_VIEWED_PURCHASE');

    // Clear discounts
    remove('DISCOUNT_COUPON');

    // Clear plan data
    remove('SELECTED_PLAN');
};

const getMarketingData = () => {
    const data = BrowserStorageService.get('MARKETING_UTM');
    return data ? JSON.parse(data) : null;
};

/**
 * This will look for Web referral attribution and will store it in the session data.
 * It would be use for registration attribution.
 * N.B.: We always keep the first found data on a session.
 */
const storeMarketingData = () => {
    /**
     * If data has been found on the session already,
     * we don't overwrite it.
     */
    let marketingData = getMarketingData();
    if (marketingData) {
        return false;
    }

    marketingData = MarketingService.getData();
    if (marketingData) {
        const string = JSON.stringify(marketingData);
        BrowserStorageService.set('MARKETING_UTM', string);
    }
};

/**
 * Retrieves a user's study plan activation data
 * @returns {object}
 */
const studyPlanGetActivationData = () => {
    const data = BrowserStorageService.get('STUDY_PLAN_ACTIVATION_DATA');
    return data ? JSON.parse(data) : null;
};

/**
 * Completely removes all activation data from localStorage
 * once a study plan has been successfully activated after a purchase.
 * NB: We also set a key that will trigger the study plan nav component to open on load
 */
const studyPlanRemoveActivationData = () => {
    BrowserStorageService.set('STUDY_PLAN_FORCE_DROPDOWN');
    BrowserStorageService.remove('STUDY_PLAN_ACTIVATION_DATA');
};

/**
 * Stores the user referral code
 * @param {Object} unique token
 */
const storeReferralUserCode = (referralCode) => {
    const string = JSON.stringify(referralCode);
    BrowserStorageService.set('REFERRAL_USER_CODE', string);
};

/**
 * Retrieves the user's unique referral code
 */
const getReferralUserCode = () => {
    const data = BrowserStorageService.get('REFERRAL_USER_CODE');
    return data ? JSON.parse(data) : null;
};

/**
 * Sets an intent for live lessons after payment.
 * This can be read in the webapp and triggers certain actions.
 * @param {Number} userId
 */
const setPostPaymentTrigger = (userId) => {
    if (!userId) {
        return false;
    }

    BrowserStorageService.set('POST_PAYMENT_TRIGGER', userId.toString());
};

/**
 * On Logout/Login/Registration, there's some keys that needs te be removed.
 * These are localStorage and sessionStorage keys.
 * Auth tokens are removed from PHP. These keys are purely product or Front-end related.
 * @param {String} type - 'login', 'logout' or 'register'
 */
const clearSpecificKeys = (type) => {
    const mapTypes = {
        logout: 'clearOnLogout',
        login: 'clearOnLogin',
        register: 'clearOnRegister',
    };
    const allkeys = BrowserStorageService.getAll();
    each(allkeys, (keyData, key) => {
        if (keyData[mapTypes[type]]) {
            remove(key);
        }
    });
};

/**
 * Returns the live source item from local storage
 * @returns {string} live source
 */
const getLiveSource = () => {
    const liveSource = CookiesStorageService.getCookie('LIVE_SOURCE');
    return liveSource ? JSON.parse(liveSource) : 'unknown';
};

/**
 * Sets the live source item to local storage
 * @param {string} liveSource item key
 */
const setLiveSource = (liveSource) => {
    const formattedData = JSON.stringify(liveSource);
    CookiesStorageService.setCookie('LIVE_SOURCE', formattedData);
};

/**
 * Returns the live source params object from local storage
 * @returns {object} live source params
 */
const getLiveSourceParams = () => {
    const liveSourceParams = CookiesStorageService.getCookie('LIVE_SOURCE_PARAMS');
    return liveSourceParams ? JSON.parse(liveSourceParams) : {};
};

/**
 * Sets the live source params object to local storage
 * @param {object} liveSourceParams
 */
const setLiveSourceParams = (params = {}) => {
    const currentData = getLiveSourceParams();
    if (!Utils.isObject(currentData)) {
        return false;
    }

    const mergedParams = {...currentData, ...params};
    const formattedData = JSON.stringify(mergedParams);
    CookiesStorageService.setCookie('LIVE_SOURCE_PARAMS', formattedData);
};

/**
 * Returns whether the alternate country site banner has been dismissed
 * @returns {boolean}
 */
const getAltCountryModalDismissed = () => {
    const data = CookiesStorageService.getCookie('ALT_COUNTRY_MODAL_DISMISSED');
    return data ? JSON.parse(data) : false;
};

/**
 * Sets whether the alternate country banner is dimsissed for the next 7 days
 * @param {boolean} dismissed
 */
const setAltCountryModalDismissed = (dismissed) => {
    CookiesStorageService.setCookie('ALT_COUNTRY_BANNER_DISMISSED', dismissed.toString(), {expires: 7});
};

/**
 * Returns whether the user opts out of non essential cookies
 * @returns {boolean}
 */
const getCookiesOptOut = () => {
    const data = CookiesStorageService.getCookie('COOKIES_OPT_OUT');
    return data ? JSON.parse(data) : false;
};

/**
 * Sets whether the user opts out of non essential cookies
 * @param {boolean} cookiesOptOut
 */
const setCookiesOptOut = (cookiesOptOut) => {
    // Remove the cookie if they opt in so we can easily check in base.html.twig for optimizely
    if (!cookiesOptOut) {
        CookiesStorageService.removeCookie('COOKIES_OPT_OUT');
    } else {
        CookiesStorageService.setCookie('COOKIES_OPT_OUT', `${cookiesOptOut}`, {
            expires: 7,
        });
    }
};

/**
 * Returns whether the cookies banner has been dismissed
 * @returns {boolean}
 */
const getCookiesBannerDismissed = () => {
    const data = CookiesStorageService.getCookie('ACCEPT_COOKIES_BANNER_DISMISSED');
    return data ? JSON.parse(data) : false;
};

/**
 * Sets whether the cookies banner is dimsissed for the next 7 days
 * @param {boolean} dismissed
 */
const setCookiesBannerDismissed = (dismissed) => {
    CookiesStorageService.setCookie('ACCEPT_COOKIES_BANNER_DISMISSED', `${dismissed}`, {expires: 7});
};

const SharedDataService = {
    remove,
    clearSpecificKeysAfterLogout: clearSpecificKeys.bind(null, 'logout'),
    clearSpecificKeysAfterLogin: clearSpecificKeys.bind(null, 'login'),
    clearSpecificKeysAfterRegistration: clearSpecificKeys.bind(null, 'register'),
    clearPaymentData,
    getDiscountCoupon,
    getSelectedPlan,
    storeDiscountCoupon,
    storeSelectedPlan,
    storePremiumPageView,
    storePurchasePageView,
    getMarketingData,
    storeMarketingData,
    storeRegistrationOnboarding,
    studyPlanGetActivationData,
    studyPlanRemoveActivationData,
    storeReferralUserCode,
    getReferralUserCode,
    setPostPaymentTrigger,
    getLiveSource,
    setLiveSource,
    getLiveSourceParams,
    setLiveSourceParams,
    getAltCountryModalDismissed,
    setAltCountryModalDismissed,
    getCookiesOptOut,
    setCookiesOptOut,
    getCookiesBannerDismissed,
    setCookiesBannerDismissed,
    getQACaptchaToken,
};

export default SharedDataService;
