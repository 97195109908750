export const ALL_USER_FREE_TRIAL = 'ALL_USER_FREE_TRIAL';
export const ALL_USER_FREE_TRIAL_ORIGINAL = 'A';
export const ALL_USER_FREE_TRIAL_VARIATION = 'B';
export const ALL_USER_FREE_TRIAL_VARIATION_NO_BANNER = 'C';
export const UNIFIED_PAYWALL_V3 = 'UNIFIED_PAYWALL_V3';
export const UNIFIED_PAYWALL_V3_ORIGINAL = 'A';
export const UNIFIED_PAYWALL_V3_VARIATION_1 = 'B';
export const UNIFIED_PAYWALL_V3_VARIATION_2 = 'C';
const experiments = {
    [ALL_USER_FREE_TRIAL]: {
        experimentId: '21568220281',
        variations: {
            21524672069: ALL_USER_FREE_TRIAL_ORIGINAL,
            21550161250: ALL_USER_FREE_TRIAL_VARIATION,
            21555342048: ALL_USER_FREE_TRIAL_VARIATION_NO_BANNER,
        },
    },
    [UNIFIED_PAYWALL_V3]: {
        experimentId: '21477300171',
        variations: {
            21475910082: UNIFIED_PAYWALL_V3_ORIGINAL,
            21467930043: UNIFIED_PAYWALL_V3_VARIATION_1,
            21475900036: UNIFIED_PAYWALL_V3_VARIATION_2,
        },
    },
};
export default experiments;
