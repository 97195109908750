import Utils from 'core-helpers/utils.js';
import NavGlide from 'components/nav-glide-class.js';
import PremiumPaywallHelper from 'paywalls/premium/premium-paywall-helper.js';
import TranslationsService from 'common/translations.service.ts';
import {buildCheckoutRequiredParams} from '@busuu/pricing';

const FILENAME = 'premium-paywall-ui-service.js';

// Elements
let tieredPlans;

// UI Classnames
const UI_ERROR_CLASSNAME = 'premium-paywall--error';
const UI_DISABLED_CLASSNAME = 'premium-paywall--disabled';
const UI_LOADING_CLASSNAME = 'premium-paywall--loading';
const UI_PRICES_VISIBLE_CLASSNAME = 'premium-paywall__price--visible';

/**
 * In order to achieve a nice effect,
 * we must allow time for the animation
 * to complete and only after it's done,
 * start it again. This value should always
 * be a little higher than the actual CSS transitionDuration.
 */
const PRICES_DELAY_BEFORE_ANIMATION = 225;

/**
 * Removes month items within the nav
 * that doesn't match the plans returned
 * from the API.
 * @param {Object} products
 */
const removeExtraPlansFromNavigation = (products) => {
    const navItems = PremiumPaywallHelper.getNavigationItems();
    navItems.forEach((item) => {
        const months = PremiumPaywallHelper.getPlanDuration(item);
        const found = products.find((product) => {
            return product.prices.find((price) => price.interval_count === months);
        });
        if (!months || !found) {
            item.parentNode.removeChild(item);
        }
    });
};

/**
 * @param {Object} options
 */
const initNav = (options) => {
    const tieredPlansNav = Utils.getById('premium-paywall-nav');
    return new NavGlide(tieredPlansNav, options);
};

/**
 * @param {Element} element
 * @param {String} text
 * @private
 */
const setText = (element, text) => {
    Utils.setText(element, text);
};

/**
 * @param {String} URL
 */
const updateSubmitButtonHref = (cta, URL, params) => {
    if (cta) {
        cta.href = `${URL}?${params}`;
    }
};

/**
 * @param {Element} prices
 * @param {String} state
 * @private
 */
const toggleShowPrices = (prices, state) => {
    if (state === 'hide') {
        return Utils.removeClass(prices, UI_PRICES_VISIBLE_CLASSNAME);
    }
    Utils.addClass(prices, UI_PRICES_VISIBLE_CLASSNAME);
};

/**
 * @param {Array} elements
 */
const resetPrices = (elements) => {
    elements.forEach((item) => {
        setText(item, '');
    });
};

/**
 * Removes the current prices and display the new prices
 * @param {Object} plan
 * @param {Element} element
 */
const updatePlanPrices = (plan, element) => {
    const originalPriceElement = Utils.getElementByClass('js-original-price', element);
    const monthlyPriceElement = Utils.getElementByClass('js-monthly-price', element);
    const discountElements = Utils.getElementsByClass('js-plan-discount', element);

    const resetElements = [originalPriceElement, monthlyPriceElement].concat(discountElements);

    resetPrices(resetElements);

    if (plan.discount_percent) {
        setText(originalPriceElement, plan.amount_monthly_human_readable);
        const discountText = TranslationsService.getTranslation('X_PERCENT').replace('%s', plan.discount_percent);
        discountElements.forEach((item) => {
            setText(item, discountText);
        });
    }

    const monthlyPrice = plan.amount_monthly_discounted
        ? plan.amount_monthly_discounted_human_readable
        : plan.amount_monthly_human_readable;

    setText(monthlyPriceElement, monthlyPrice);
};

/**
 * Removes the current billed term and displays the new one
 * @param {Object} plan
 * @param {Element} element
 */
const updatePlanBilledTerm = (plan, element) => {
    const billedTermElement = Utils.getElementByClass('js-billed-term', element);

    const billedTermKey = plan.interval_count === 1 ? '1_MONTH_BILLED' : `${plan.interval_count}_MONTHS_BILLED`;

    setText(billedTermElement, TranslationsService.getTranslation(billedTermKey));
};

/**
 * Updates the current plans details with an animation effect.
 * @param {object} props
 * @param {object} props.plan
 * @param {element} props.element
 * @param {string} props.cohort - pricing cohort
 * @param {string} props.planType - type of plan ('serious' or 'casual')
 * @param {boolean} props.authenticated - is user authenticated?
 */
const updatePlanDetails = ({plan, element, cohort, planType, authenticated}) => {
    const prices = Utils.getElementByClass('js-prices', element);
    const URL = PremiumPaywallHelper.getCartURL();
    const params = buildCheckoutRequiredParams(plan.id, cohort);

    const showPrices = () => {
        updatePlanPrices(plan, element);
        updatePlanBilledTerm(plan, element);
        toggleShowPrices(prices, 'show');
    };

    toggleShowPrices(prices, 'hide');
    setTimeout(showPrices, PRICES_DELAY_BEFORE_ANIMATION);

    // Ensure we don't update any CTA from /login to /purchase if unauthenticated
    if (!authenticated) return false;

    // Update desktop table buttons...
    const desktopCtas = Utils.getElementsByClass('js-table-plan-cta');
    desktopCtas.forEach((desktopBtn) => {
        const tierAttribute = desktopBtn.getAttribute('data-tier-type');
        if (tierAttribute === planType) {
            updateSubmitButtonHref(desktopBtn, URL, params);
        }
    });

    // Update mobile buttons...
    const mobileCta = Utils.getElementByClass('js-submit-plan', element);
    if (mobileCta) {
        updateSubmitButtonHref(mobileCta, URL, params);
    }
};

/**
 * @param {String} type
 * @private
 */
const showError = (type) => {
    const tieredPlansError = Utils.getById('premium-paywall-error');
    setText(tieredPlansError, TranslationsService.getTranslation(type));
};

/**
 * UI Module states
 * @param {String} state
 * @param {String} errorType
 */
const setState = (state, errorType = 'ERROR') => {
    tieredPlans = Utils.getById('premium-paywall');

    switch (state) {
        case 'REQUEST_START':
            Utils.addClass(tieredPlans, `${UI_LOADING_CLASSNAME} ${UI_DISABLED_CLASSNAME}`);
            break;
        case 'REQUEST_COMPLETE':
            Utils.removeClass(tieredPlans, UI_LOADING_CLASSNAME);
            break;
        case 'REQUEST_SUCCESS':
            Utils.removeClass(tieredPlans, UI_DISABLED_CLASSNAME);
            break;
        case 'ERROR':
            showError(errorType);
            Utils.removeClass(tieredPlans, UI_LOADING_CLASSNAME);
            Utils.addClass(tieredPlans, `${UI_ERROR_CLASSNAME} ${UI_DISABLED_CLASSNAME}`);
            break;
        default:
            throw new Error(`[${FILENAME}]: setState() - state expected`);
    }
};

/**
 * Updated the title and flag in "Paywall Customized by language learnt"
 * @param {Object} data
 * @public
 */
const paywallCustomizedByLanguageLearnt = ({learning_default: learningDefault}) => {
    if (!learningDefault) {
        return false;
    }

    const getTranslations = (language) => {
        const lang = TranslationsService.getTranslation(`LANGUAGE_${language}`);
        const msg = TranslationsService.getAndReplaceTranslation('UNLOCK_THE_FULL_LANGUAGE_X_COURSE', lang);
        return {lang, msg};
    };

    const getFlagSrc = (language) => {
        return `${process.env.staticRoot}/img/icons/flags/icon_flag-${language.toLowerCase()}.svg`;
    };

    const {lang, msg} = getTranslations(learningDefault.toLowerCase());
    const flagSrc = getFlagSrc(learningDefault.toLowerCase());

    const flagElement = document.createElement('img');

    const attrs = {
        src: flagSrc,
        width: 50,
        height: 50,
        class: 'premium-paywall__main-title-flag',
        alt: lang,
    };

    Object.entries(attrs).forEach(([key, value]) => {
        flagElement.setAttribute(key, value);
    });

    const title = Utils.getElementByClass('premium-paywall__main-title');

    const showNewTitle = () => {
        title.removeEventListener('animationend', showNewTitle);
        Utils.setText(title, msg);
        title.appendChild(flagElement);
        Utils.removeClass(title, 'premium-paywall__main-title--fadeout');
        Utils.addClass(title, 'premium-paywall__main-title--flagged');
    };

    title.addEventListener('animationend', showNewTitle);

    Utils.addClass(title, 'premium-paywall__main-title--fadeout');
};

/**
 * Tiered Plans UI Service:
 * This service should hold any methods that is changing the UI
 * or the DOM module.
 */
const PremiumPaywallUIService = {
    setState,
    initNav,
    updatePlanDetails,
    removeExtraPlansFromNavigation,
    setText,
    paywallCustomizedByLanguageLearnt,
};

export default PremiumPaywallUIService;
