import Utils from 'core-helpers/utils.js';
import ItemRotatorProgress from 'components/item-rotator-progress-class.js';

const init = () => {
    const appPreviewWrapper = Utils.getById('app-preview');

    if (appPreviewWrapper) {
        new ItemRotatorProgress(appPreviewWrapper, {
            interval: 3000,
            itemPrevClass: 'app-preview__slide--overlaid',
            itemActiveClass: 'app-preview__slide--active',
            indicatorActiveClass: 'app-preview__indicator--active',
        });
    }
};

init();
