import Utils from 'core-helpers/utils.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import ExceptionConstants from 'exceptions/exception-constants.js';

const DSN = 'https://45a54af00d554ae28ee9a033eec1415e@sentry.io/1249934'; // key ID

/**
 * @return {Sentry Integration Instance}
 */
const getInboundFiltersInstance = () => {
    return ExceptionService.getSentryIntegrationInstance('InboundFilters', {
        ignoreErrors: ExceptionConstants.getIgnoreErrorsList(),
    });
};

/**
 * We allow default integrations for production.
 * @type {Object}
 */
const SENTRY_INTEGRATIONS_PROD = {
    InboundFilters: {
        overwriteIntegrationWith: () => {
            return getInboundFiltersInstance();
        },
    },
};

/**
 * For other environments we don't want Breadcrumbs as it doesn't returns
 * the right source map in devtools console.
 * https://github.com/getsentry/sentry-javascript/issues/1638
 * @type {Object}
 */
const SENTRY_INTEGRATIONS = {
    Breadcrumbs: {
        disabled: true,
    },
    InboundFilters: {
        overwriteIntegrationWith: () => {
            return getInboundFiltersInstance();
        },
    },
};

/**
 * @param {String} integrationName
 * @return {Object}
 */
const getIntegrationConfig = (integrationName) => {
    const config = Utils.isProduction() ? SENTRY_INTEGRATIONS_PROD : SENTRY_INTEGRATIONS;
    return config[integrationName] || null;
};

/**
 * Some integrations can be passed when initialiasing
 * the Sentry SDK, we overwrite and disable some of them here.
 * Doc: https://docs.sentry.io/platforms/javascript/#sdk-integrations
 * @param {Array} defaultIntegrations
 * @return {Array}
 */
const getIntegrations = (defaultIntegrations) => {
    return defaultIntegrations
        .map((integration) => {
            const config = getIntegrationConfig(integration.name);

            // Integration is disabled, removing it.
            if (config && config.disabled) {
                return null;
            }

            // Integration has different config, overwriting it.
            if (config && !config.disabled && config.overwriteIntegrationWith) {
                return config.overwriteIntegrationWith();
            }

            // Default integration has not changed, keep unchanged.
            return integration;
        })
        .filter(Boolean); // filter out falsy values
};

const getConfig = () => {
    return {
        key: DSN,
        enableLocal: false,
        sentryOptions: {
            release: process.env.version,
            integrations: (defaultIntegrations) => {
                const integrations = getIntegrations(defaultIntegrations);
                return integrations;
            },
        },
    };
};

const init = () => {
    ExceptionService.init(getConfig());
};

init();
