import Utils from 'core-helpers/utils.js';
import PromotionBanner from 'promotion/promotion-banner.js';
import {findCheapestAvailablePrice} from '@busuu/pricing';
import {getPricing} from 'pricing/pricing-service';
import {getStandardPricingCohort} from 'experiments/pricing-experiment';

const BANNER_ID = 'promotion-banner';

const onSuccess = (promotionBanner, response) => {
    const cheapestPlan = findCheapestAvailablePrice(response);
    new PromotionBanner(promotionBanner, cheapestPlan.interval_count);
};

const init = () => {
    const promotionBanner = Utils.getById(BANNER_ID);
    if (promotionBanner) {
        const pricingCohort = getStandardPricingCohort();

        getPricing(pricingCohort)
            .then(onSuccess.bind(null, promotionBanner))
            .catch(() => {});
    }
};

init();
