const EndpointConstant = {
    endpoints: {
        // User & Auth
        autoauth: '/anon/sandbox',
        autologin: '/anon/login/{type}',
        captcha: '/anon/config?platform=web',
        currentUser: '/users/me',
        forgottenPassword: '/anon/forgotten-password',
        login: '/anon/login',
        loginFacebook: '/anon/login/facebook',
        loginGoogle: '/anon/login/googlecloud',
        register: '/anon/register',
        registerVerification: '/anon/validate',
        registerFacebook: '/anon/register/facebook',
        registerGoogle: '/anon/register/googlecloud',
        resetPassword: '/anon/reset-password',
        user: '/users/{uid}',
        userUpdate: '/users/{uid}',
        acceptTermsConditions: '/anon/accept-terms-conditions',

        // Course & Study Plan
        courseProgress: '/api/v2/progress/{language}',
        progressStats: '/progress/users/{uid}/stats',
        studyPlanActivate: '/study_plan/{id}/activate',
        courseConfig: '/api/anon/course-config',

        // Braintree / Paypal payment
        braintreeToken: '/api/braintree/token',
        braintreeCheckout: '/api/braintree/checkout',

        // B2B
        institutionEmailAssociated:
            '/anon/invites/find?institution_name={institution_name}&institution_code={institution_code}',

        // Promotions & vouchers
        promotion: '/promotion?platform=web&interface_language={interface_language}',
        referralData: '/anon/referral-tokens/{referral_code}',
        referralExerciseData: '/anon/exercise-tokens/{exercise_code}',
        voucherRedemption: '/vouchers/redemption',
        unsubscribe: '/anon/users/unsubscribe?email={email}&user_id={user_id}',

        // Busuu Live
        busuuLive: '/',
    },
    absoluteEndpoints: {
        // 'endpointNameExample': 'https://www.example.com/endpoint'
    },
};

export default EndpointConstant;
