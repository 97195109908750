import TimeHelper from 'core-helpers/time-helper.js';
import TranslationsService from 'common/translations.service.ts';
import FreeTrialBannerBase from 'free-trial/free-trial-banner/free-trial-banner-base.js';
import CurrentUser from 'user/current-user-service.js';
import {FREE_TRIAL_PLAN} from '@busuu/pricing';

class FreeTrialBannerBasic extends FreeTrialBannerBase {
    constructor(freeTrialDays, options) {
        super(freeTrialDays, options);

        this.initCountdown = this.initCountdown.bind(this);
        this.updateCountdownContent = this.updateCountdownContent.bind(this);
        this.cancelCountdown = this.cancelCountdown.bind(this);
    }

    onInit(freeTrialDays) {
        const onSuccess = ({created}) => {
            const freeTrialTimeRemaining = TimeHelper.calculateTimeDifference(created + FREE_TRIAL_PLAN.EXPIRY_SECONDS);

            if (freeTrialTimeRemaining > 0) {
                this.initCountdown(freeTrialTimeRemaining);
                this.initCTA();
                this.show();
            }
        };

        if (freeTrialDays > 0) {
            CurrentUser.get()
                .then(onSuccess)
                .catch(() => {});
        }
    }

    initCTA() {
        this.trialCTA.text = TranslationsService.getTranslation('GET');
    }

    initCountdown(freeTrialTimeRemaining) {
        let remainingTime = freeTrialTimeRemaining;

        this.updateCountdownContent(remainingTime);

        const countdown = setInterval(() => {
            remainingTime -= 1;

            this.updateCountdownContent(remainingTime);

            if (remainingTime <= 0) {
                this.cancelCountdown(countdown);
            }
        }, 1000);
    }

    updateCountdownContent(remainingTime) {
        const formattedTime = TimeHelper.secsToHumanReadable(remainingTime);
        const content = TranslationsService.getAndReplaceTranslationMulti('LAST_CHANCE_TO_TRY_PREMIUM', [
            formattedTime,
        ]);
        this.updateContent(content);
    }

    cancelCountdown(countdown) {
        if (countdown) {
            clearInterval(countdown);
        }
        this.hide();
    }
}

export default FreeTrialBannerBasic;
