/**
 * A service to provide data storage using cookies.
 * NB: Only use sporadically, BrowserStorageService is preferred when applicable,
 * but localStorage/sessionStorage doesn't apply cross sub-domain
 * (for eg between www.busuu.com and live.busuu.com)
 */
import Cookies from 'js-cookie';
import Utils from 'core-helpers/utils.js';

/**
 * Sets a cookie
 * @param {String} name
 * @param {String} value
 * @param {Object} opts
 */
const setCookie = (name, value, opts = {}) => {
    const domain = '.busuu.com';

    Cookies.set(name, value, {
        sameSite: 'strict',
        domain,
        ...opts
    });
};

/**
 * Gets a cookie
 * @param {String} name
 */
const getCookie = (name) => {
    return Cookies.get(name);
};

/**
 * Removes a cookie
 * @param {String} name
 */
const removeCookie = (name) => {
    Cookies.remove(name);
};

export default {
    setCookie,
    getCookie,
    removeCookie
};
