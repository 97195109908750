import Utils from 'core-helpers/utils.js';
export const V3_PAYWALL_FT_INFO_PAGE = 'free-trial-info';
export const V3_PAYWALL_PLANS_PAGE = 'plans';
/**
 *
 * Retrieves current language
 */
const getCurrentLanguage = () => {
    return window.BUSUU_GLOBALS.LANG_INTERFACE || 'en';
};
/**
 * Retrieves url for the 3.0 paywall app (web-payments)
 */
export const getV3PaywallUrl = (page) => {
    const currentLang = getCurrentLanguage();
    const localUrl = 'https://local.internal.busuu.com:3000/products';
    let baseUrl = `${window.location.origin}/products`;
    if (Utils.isLocalEnvironment()) {
        baseUrl = localUrl;
    }
    switch (page) {
        case V3_PAYWALL_FT_INFO_PAGE:
            return `${baseUrl}/${currentLang}/${V3_PAYWALL_FT_INFO_PAGE}`;
        case V3_PAYWALL_PLANS_PAGE:
            return `${baseUrl}/${currentLang}/${V3_PAYWALL_PLANS_PAGE}`;
        default:
            return `${baseUrl}/${currentLang}`;
    }
};
