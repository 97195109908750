/**
 * A list of countries available for the user.
 * Would be ideal to have a single source of truth from the back-end
 * because there are differences between versions of the clients
 */
const CountriesConstants = {
    'AW': { label: 'COUNTRY_AW', code: 'AW', callingCode: '297' }, // Aruba
    'AF': { label: 'COUNTRY_AF', code: 'AF', callingCode: '93' }, // Afghanistan
    'AO': { label: 'COUNTRY_AO', code: 'AO', callingCode: '244' }, // Angola
    'AI': { label: 'COUNTRY_AI', code: 'AI', callingCode: '1264' }, // Anguilla
    'AL': { label: 'COUNTRY_AL', code: 'AL', callingCode: '355' }, // Albania
    'AD': { label: 'COUNTRY_AD', code: 'AD', callingCode: '376' }, // Andorra
    'AE': { label: 'COUNTRY_AE', code: 'AE', callingCode: '971' }, // United Arab Emirates
    'AR': { label: 'COUNTRY_AR', code: 'AR', callingCode: '54' }, // Argentina
    'AM': { label: 'COUNTRY_AM', code: 'AM', callingCode: '374' }, // Armenia
    'AS': { label: 'COUNTRY_AS', code: 'AS', callingCode: '1684' }, // American Samoa
    'AG': { label: 'COUNTRY_AG', code: 'AG', callingCode: '1268' }, // Antigua and Barbuda
    'AU': { label: 'COUNTRY_AU', code: 'AU', callingCode: '61' }, // Australia
    'AT': { label: 'COUNTRY_AT', code: 'AT', callingCode: '43' }, // Austria
    'AZ': { label: 'COUNTRY_AZ', code: 'AZ', callingCode: '994' }, // Azerbaijan
    'BI': { label: 'COUNTRY_BI', code: 'BI', callingCode: '257' }, // Burundi
    'BE': { label: 'COUNTRY_BE', code: 'BE', callingCode: '32' }, // Belgium
    'BJ': { label: 'COUNTRY_BJ', code: 'BJ', callingCode: '229' }, // Benin
    'BF': { label: 'COUNTRY_BF', code: 'BF', callingCode: '226' }, // Burkina Faso
    'BD': { label: 'COUNTRY_BD', code: 'BD', callingCode: '880' }, // Bangladesh
    'BG': { label: 'COUNTRY_BG', code: 'BG', callingCode: '359' }, // Bulgaria
    'BH': { label: 'COUNTRY_BH', code: 'BH', callingCode: '973' }, // Bahrain
    'BS': { label: 'COUNTRY_BS', code: 'BS', callingCode: '1242' }, // Bahamas
    'BA': { label: 'COUNTRY_BA', code: 'BA', callingCode: '387' }, // Bosnia and Herzegovina
    'BY': { label: 'COUNTRY_BY', code: 'BY', callingCode: '375' }, // Belarus
    'BZ': { label: 'COUNTRY_BZ', code: 'BZ', callingCode: '501' }, // Belize
    'BM': { label: 'COUNTRY_BM', code: 'BM', callingCode: '1441' }, // Bermuda
    'BO': { label: 'COUNTRY_BO', code: 'BO', callingCode: '591' }, // Bolivia
    'BQ': { label: 'COUNTRY_BQ', code: 'BQ', callingCode: '599' }, // Bonaire
    'BR': { label: 'COUNTRY_BR', code: 'BR', callingCode: '55' }, // Brazil
    'BB': { label: 'COUNTRY_BB', code: 'BB', callingCode: '1246' }, // Barbados
    'BN': { label: 'COUNTRY_BN', code: 'BN', callingCode: '673' }, // Brunei
    'BT': { label: 'COUNTRY_BT', code: 'BT', callingCode: '975' }, // Bhutan
    'BW': { label: 'COUNTRY_BW', code: 'BW', callingCode: '267' }, // Botswana
    'CF': { label: 'COUNTRY_CF', code: 'CF', callingCode: '236' }, // Central African Republic
    'CA': { label: 'COUNTRY_CA', code: 'CA', callingCode: '1' }, // Canada
    'CC': { label: 'COUNTRY_CC', code: 'CC', callingCode: '61' }, // Cocos (Keeling) Islands
    'CH': { label: 'COUNTRY_CH', code: 'CH', callingCode: '41' }, // Switzerland
    'CL': { label: 'COUNTRY_CL', code: 'CL', callingCode: '56' }, // Chile
    'CN': { label: 'COUNTRY_CN', code: 'CN', callingCode: '86' }, // China
    'CI': { label: 'COUNTRY_CI', code: 'CI', callingCode: '225' }, // Ivory Coast
    'CM': { label: 'COUNTRY_CM', code: 'CM', callingCode: '237' }, // Cameroon
    'CG': { label: 'COUNTRY_CG', code: 'CG', callingCode: '242' }, // Republic of the Congo
    'CD': { label: 'COUNTRY_CD', code: 'CD', callingCode: '243' }, // Democratic Republic of Congo
    'CK': { label: 'COUNTRY_CK', code: 'CK', callingCode: '682' }, // Cook Islands
    'CO': { label: 'COUNTRY_CO', code: 'CO', callingCode: '57' }, // Colombia
    'KM': { label: 'COUNTRY_KM', code: 'KM', callingCode: '269' }, // Comoros
    'CV': { label: 'COUNTRY_CV', code: 'CV', callingCode: '238' }, // Cape Verde
    'CR': { label: 'COUNTRY_CR', code: 'CR', callingCode: '506' }, // Costa Rica
    'CU': { label: 'COUNTRY_CU', code: 'CU', callingCode: '53' }, // Cuba
    'CW': { label: 'COUNTRY_CW', code: 'CW', callingCode: '599' }, // Curaçao
    'CX': { label: 'COUNTRY_CX', code: 'CX', callingCode: '61' }, // Christmas Island
    'KY': { label: 'COUNTRY_KY', code: 'KY', callingCode: '1345' }, // Cayman Islands
    'CY': { label: 'COUNTRY_CY', code: 'CY', callingCode: '357' }, // Cyprus
    'CZ': { label: 'COUNTRY_CZ', code: 'CZ', callingCode: '420' }, // Czechia
    'DE': { label: 'COUNTRY_DE', code: 'DE', callingCode: '49' }, // Germany
    'DJ': { label: 'COUNTRY_DJ', code: 'DJ', callingCode: '253' }, // Djibouti
    'DM': { label: 'COUNTRY_DM', code: 'DM', callingCode: '1767' }, // Dominica
    'DK': { label: 'COUNTRY_DK', code: 'DK', callingCode: '45' }, // Denmark
    'DO': { label: 'COUNTRY_DO', code: 'DO', callingCode: '1' }, // Dominican Republic
    'DZ': { label: 'COUNTRY_DZ', code: 'DZ', callingCode: '213' }, // Algeria
    'EC': { label: 'COUNTRY_EC', code: 'EC', callingCode: '593' }, // Ecuador
    'EG': { label: 'COUNTRY_EG', code: 'EG', callingCode: '20' }, // Egypt
    'ER': { label: 'COUNTRY_ER', code: 'ER', callingCode: '291' }, // Eritrea
    'EH': { label: 'COUNTRY_EH', code: 'EH', callingCode: '212' }, // Western Sahara
    'ES': { label: 'COUNTRY_ES', code: 'ES', callingCode: '34' }, // Spain
    'EE': { label: 'COUNTRY_EE', code: 'EE', callingCode: '372' }, // Estonia
    'ET': { label: 'COUNTRY_ET', code: 'ET', callingCode: '251' }, // Ethiopia
    'FI': { label: 'COUNTRY_FI', code: 'FI', callingCode: '358' }, // Finland
    'FJ': { label: 'COUNTRY_FJ', code: 'FJ', callingCode: '679' }, // Fiji
    'FK': { label: 'COUNTRY_FK', code: 'FK', callingCode: '500' }, // Falkland Islands
    'FR': { label: 'COUNTRY_FR', code: 'FR', callingCode: '33' }, // France
    'FO': { label: 'COUNTRY_FO', code: 'FO', callingCode: '298' }, // Faroe Islands
    'GA': { label: 'COUNTRY_GA', code: 'GA', callingCode: '241' }, // Gabon
    'GB': { label: 'COUNTRY_GB', code: 'GB', callingCode: '44' }, // United Kingdom
    'GE': { label: 'COUNTRY_GE', code: 'GE', callingCode: '995' }, // Georgia
    'GG': { label: 'COUNTRY_GG', code: 'GG', callingCode: '44' }, // Guernsey
    'GH': { label: 'COUNTRY_GH', code: 'GH', callingCode: '233' }, // Ghana
    'GI': { label: 'COUNTRY_GI', code: 'GI', callingCode: '350' }, // Gibraltar
    'GN': { label: 'COUNTRY_GN', code: 'GN', callingCode: '224' }, // Guinea
    'GP': { label: 'COUNTRY_GP', code: 'GP', callingCode: '590' }, // Guadeloupe
    'GM': { label: 'COUNTRY_GM', code: 'GM', callingCode: '220' }, // Gambia
    'GW': { label: 'COUNTRY_GW', code: 'GW', callingCode: '245' }, // Guinea-Bissau
    'GQ': { label: 'COUNTRY_GQ', code: 'GQ', callingCode: '240' }, // Equatorial Guinea
    'GR': { label: 'COUNTRY_GR', code: 'GR', callingCode: '30' }, // Greece
    'GD': { label: 'COUNTRY_GD', code: 'GD', callingCode: '1473' }, // Grenada
    'GL': { label: 'COUNTRY_GL', code: 'GL', callingCode: '299' }, // Greenland
    'GT': { label: 'COUNTRY_GT', code: 'GT', callingCode: '502' }, // Guatemala
    'GF': { label: 'COUNTRY_GF', code: 'GF', callingCode: '594' }, // French Guiana
    'GU': { label: 'COUNTRY_GU', code: 'GU', callingCode: '1671' }, // Guam
    'GY': { label: 'COUNTRY_GY', code: 'GY', callingCode: '592' }, // Guyana
    'HK': { label: 'COUNTRY_HK', code: 'HK', callingCode: '852' }, // Hong Kong
    'HN': { label: 'COUNTRY_HN', code: 'HN', callingCode: '504' }, // Honduras
    'HR': { label: 'COUNTRY_HR', code: 'HR', callingCode: '385' }, // Croatia
    'HT': { label: 'COUNTRY_HT', code: 'HT', callingCode: '509' }, // Haiti
    'HU': { label: 'COUNTRY_HU', code: 'HU', callingCode: '36' }, // Hungary
    'ID': { label: 'COUNTRY_ID', code: 'ID', callingCode: '62' }, // Indonesia
    'IM': { label: 'COUNTRY_IM', code: 'IM', callingCode: '44' }, // Isle of Man
    'IN': { label: 'COUNTRY_IN', code: 'IN', callingCode: '91' }, // India
    'IO': { label: 'COUNTRY_IO', code: 'IO', callingCode: '246' }, // British Indian Ocean Territory
    'IE': { label: 'COUNTRY_IE', code: 'IE', callingCode: '353' }, // Ireland
    'IR': { label: 'COUNTRY_IR', code: 'IR', callingCode: '98' }, // Iran
    'IQ': { label: 'COUNTRY_IQ', code: 'IQ', callingCode: '964' }, // Iraq
    'IS': { label: 'COUNTRY_IS', code: 'IS', callingCode: '354' }, // Iceland
    'IL': { label: 'COUNTRY_IL', code: 'IL', callingCode: '972' }, // Israel
    'IT': { label: 'COUNTRY_IT', code: 'IT', callingCode: '39' }, // Italy
    'JM': { label: 'COUNTRY_JM', code: 'JM', callingCode: '1876' }, // Jamaica
    'JE': { label: 'COUNTRY_JE', code: 'JE', callingCode: '44' }, // Jersey
    'JO': { label: 'COUNTRY_JO', code: 'JO', callingCode: '962' }, // Jordan
    'JP': { label: 'COUNTRY_JP', code: 'JP', callingCode: '81' }, // Japan
    'KZ': { label: 'COUNTRY_KZ', code: 'KZ', callingCode: '7' }, // Kazakhstan
    'KE': { label: 'COUNTRY_KE', code: 'KE', callingCode: '254' }, // Kenya
    'KG': { label: 'COUNTRY_KG', code: 'KG', callingCode: '996' }, // Kyrgyzstan
    'KH': { label: 'COUNTRY_KH', code: 'KH', callingCode: '855' }, // Cambodia
    'KI': { label: 'COUNTRY_KI', code: 'KI', callingCode: '686' }, // Kiribati
    'KN': { label: 'COUNTRY_KN', code: 'KN', callingCode: '1869' }, // Saint Kitts and Nevis
    'KR': { label: 'COUNTRY_KR', code: 'KR', callingCode: '82' }, // South Korea
    'XK': { label: 'COUNTRY_XK', code: 'XK', callingCode: '383' }, // Kosovo
    'KW': { label: 'COUNTRY_KW', code: 'KW', callingCode: '965' }, // Kuwait
    'LA': { label: 'COUNTRY_LA', code: 'LA', callingCode: '856' }, // Laos
    'LB': { label: 'COUNTRY_LB', code: 'LB', callingCode: '961' }, // Lebanon
    'LR': { label: 'COUNTRY_LR', code: 'LR', callingCode: '231' }, // Liberia
    'LY': { label: 'COUNTRY_LY', code: 'LY', callingCode: '218' }, // Libya
    'LC': { label: 'COUNTRY_LC', code: 'LC', callingCode: '1758' }, // Saint Lucia
    'LI': { label: 'COUNTRY_LI', code: 'LI', callingCode: '423' }, // Liechtenstein
    'LK': { label: 'COUNTRY_LK', code: 'LK', callingCode: '94' }, // Sri Lanka
    'LS': { label: 'COUNTRY_LS', code: 'LS', callingCode: '266' }, // Lesotho
    'LT': { label: 'COUNTRY_LT', code: 'LT', callingCode: '370' }, // Lithuania
    'LU': { label: 'COUNTRY_LU', code: 'LU', callingCode: '352' }, // Luxembourg
    'LV': { label: 'COUNTRY_LV', code: 'LV', callingCode: '371' }, // Latvia
    'MO': { label: 'COUNTRY_MO', code: 'MO', callingCode: '853' }, // Macau
    'MA': { label: 'COUNTRY_MA', code: 'MA', callingCode: '212' }, // Morocco
    'MC': { label: 'COUNTRY_MC', code: 'MC', callingCode: '377' }, // Monaco
    'MD': { label: 'COUNTRY_MD', code: 'MD', callingCode: '373' }, // Moldova
    'MG': { label: 'COUNTRY_MG', code: 'MG', callingCode: '261' }, // Madagascar
    'MV': { label: 'COUNTRY_MV', code: 'MV', callingCode: '960' }, // Maldives
    'MX': { label: 'COUNTRY_MX', code: 'MX', callingCode: '52' }, // Mexico
    'MH': { label: 'COUNTRY_MH', code: 'MH', callingCode: '692' }, // Marshall Islands
    'MK': { label: 'COUNTRY_MK', code: 'MK', callingCode: '389' }, // Macedonia
    'ML': { label: 'COUNTRY_ML', code: 'ML', callingCode: '223' }, // Mali
    'MT': { label: 'COUNTRY_MT', code: 'MT', callingCode: '356' }, // Malta
    'MM': { label: 'COUNTRY_MM', code: 'MM', callingCode: '95' }, // Myanmar
    'ME': { label: 'COUNTRY_ME', code: 'ME', callingCode: '382' }, // Montenegro
    'MN': { label: 'COUNTRY_MN', code: 'MN', callingCode: '976' }, // Mongolia
    'MP': { label: 'COUNTRY_MP', code: 'MP', callingCode: '1670' }, // Northern Mariana Islands
    'MZ': { label: 'COUNTRY_MZ', code: 'MZ', callingCode: '258' }, // Mozambique
    'MR': { label: 'COUNTRY_MR', code: 'MR', callingCode: '222' }, // Mauritania
    'MS': { label: 'COUNTRY_MS', code: 'MS', callingCode: '1664' }, // Montserrat
    'MQ': { label: 'COUNTRY_MQ', code: 'MQ', callingCode: '596' }, // Martinique
    'MU': { label: 'COUNTRY_MU', code: 'MU', callingCode: '230' }, // Mauritius
    'MW': { label: 'COUNTRY_MW', code: 'MW', callingCode: '265' }, // Malawi
    'MY': { label: 'COUNTRY_MY', code: 'MY', callingCode: '60' }, // Malaysia
    'YT': { label: 'COUNTRY_YT', code: 'YT', callingCode: '262' }, // Mayotte
    'NA': { label: 'COUNTRY_NA', code: 'NA', callingCode: '264' }, // Namibia
    'NC': { label: 'COUNTRY_NC', code: 'NC', callingCode: '687' }, // New Caledonia
    'NE': { label: 'COUNTRY_NE', code: 'NE', callingCode: '227' }, // Niger
    'NF': { label: 'COUNTRY_NF', code: 'NF', callingCode: '672' }, // Norfolk Island
    'NG': { label: 'COUNTRY_NG', code: 'NG', callingCode: '234' }, // Nigeria
    'NI': { label: 'COUNTRY_NI', code: 'NI', callingCode: '505' }, // Nicaragua
    'NL': { label: 'COUNTRY_NL', code: 'NL', callingCode: '31' }, // Netherlands
    'NO': { label: 'COUNTRY_NO', code: 'NO', callingCode: '47' }, // Norway
    'NP': { label: 'COUNTRY_NP', code: 'NP', callingCode: '977' }, // Nepal
    'NR': { label: 'COUNTRY_NR', code: 'NR', callingCode: '674' }, // Nauru
    'NZ': { label: 'COUNTRY_NZ', code: 'NZ', callingCode: '64' }, // New Zealand
    'OM': { label: 'COUNTRY_OM', code: 'OM', callingCode: '968' }, // Oman
    'PK': { label: 'COUNTRY_PK', code: 'PK', callingCode: '92' }, // Pakistan
    'PA': { label: 'COUNTRY_PA', code: 'PA', callingCode: '507' }, // Panama
    'PN': { label: 'COUNTRY_PN', code: 'PN', callingCode: '64' }, // Pitcairn Islands
    'PE': { label: 'COUNTRY_PE', code: 'PE', callingCode: '51' }, // Peru
    'PH': { label: 'COUNTRY_PH', code: 'PH', callingCode: '63' }, // Philippines
    'PW': { label: 'COUNTRY_PW', code: 'PW', callingCode: '680' }, // Palau
    'PG': { label: 'COUNTRY_PG', code: 'PG', callingCode: '675' }, // Papua New Guinea
    'PL': { label: 'COUNTRY_PL', code: 'PL', callingCode: '48' }, // Poland
    'PR': { label: 'COUNTRY_PR', code: 'PR', callingCode: '1' }, // Puerto Rico
    'KP': { label: 'COUNTRY_KP', code: 'KP', callingCode: '850' }, // North Korea
    'PT': { label: 'COUNTRY_PT', code: 'PT', callingCode: '351' }, // Portugal
    'PY': { label: 'COUNTRY_PY', code: 'PY', callingCode: '595' }, // Paraguay
    'PS': { label: 'COUNTRY_PS', code: 'PS', callingCode: '970' }, // Palestine
    'PF': { label: 'COUNTRY_PF', code: 'PF', callingCode: '689' }, // French Polynesia
    'QA': { label: 'COUNTRY_QA', code: 'QA', callingCode: '974' }, // Qatar
    'RE': { label: 'COUNTRY_RE', code: 'RE', callingCode: '262' }, // Réunion
    'RO': { label: 'COUNTRY_RO', code: 'RO', callingCode: '40' }, // Romania
    'RU': { label: 'COUNTRY_RU', code: 'RU', callingCode: '7' }, // Russia
    'RW': { label: 'COUNTRY_RW', code: 'RW', callingCode: '250' }, // Rwanda
    'SA': { label: 'COUNTRY_SA', code: 'SA', callingCode: '966' }, // Saudi Arabia
    'SD': { label: 'COUNTRY_SD', code: 'SD', callingCode: '249' }, // Sudan
    'SN': { label: 'COUNTRY_SN', code: 'SN', callingCode: '221' }, // Senegal
    'SG': { label: 'COUNTRY_SG', code: 'SG', callingCode: '65' }, // Singapore
    'SJ': { label: 'COUNTRY_SJ', code: 'SJ', callingCode: '4779' }, // Svalbard and Jan Mayen
    'SB': { label: 'COUNTRY_SB', code: 'SB', callingCode: '677' }, // Solomon Islands
    'SL': { label: 'COUNTRY_SL', code: 'SL', callingCode: '232' }, // Sierra Leone
    'SV': { label: 'COUNTRY_SV', code: 'SV', callingCode: '503' }, // El Salvador
    'SM': { label: 'COUNTRY_SM', code: 'SM', callingCode: '378' }, // San Marino
    'SO': { label: 'COUNTRY_SO', code: 'SO', callingCode: '252' }, // Somalia
    'PM': { label: 'COUNTRY_PM', code: 'PM', callingCode: '508' }, // Saint Pierre and Miquelon
    'RS': { label: 'COUNTRY_RS', code: 'RS', callingCode: '381' }, // Serbia
    'ST': { label: 'COUNTRY_ST', code: 'ST', callingCode: '239' }, // São Tomé and Príncipe
    'SR': { label: 'COUNTRY_SR', code: 'SR', callingCode: '597' }, // Suriname
    'SK': { label: 'COUNTRY_SK', code: 'SK', callingCode: '421' }, // Slovakia
    'SI': { label: 'COUNTRY_SI', code: 'SI', callingCode: '386' }, // Slovenia
    'SE': { label: 'COUNTRY_SE', code: 'SE', callingCode: '46' }, // Sweden
    'SZ': { label: 'COUNTRY_SZ', code: 'SZ', callingCode: '268' }, // Swaziland
    'SX': { label: 'COUNTRY_SX', code: 'SX', callingCode: '599' }, // Sint Maarten
    'SS': { label: 'COUNTRY_SS', code: 'SS', callingCode: '211' }, // South Sudan
    'SC': { label: 'COUNTRY_SC', code: 'SC', callingCode: '248' }, // Seychelles
    'SY': { label: 'COUNTRY_SY', code: 'SY', callingCode: '963' }, // Syria
    'TC': { label: 'COUNTRY_TC', code: 'TC', callingCode: '1649' }, // Turks and Caicos Islands
    'TD': { label: 'COUNTRY_TD', code: 'TD', callingCode: '235' }, // Chad
    'TG': { label: 'COUNTRY_TG', code: 'TG', callingCode: '228' }, // Togo
    'TH': { label: 'COUNTRY_TH', code: 'TH', callingCode: '66' }, // Thailand
    'TJ': { label: 'COUNTRY_TJ', code: 'TJ', callingCode: '992' }, // Tajikistan
    'TK': { label: 'COUNTRY_TK', code: 'TK', callingCode: '690' }, // Tokelau
    'TM': { label: 'COUNTRY_TM', code: 'TM', callingCode: '993' }, // Turkmenistan
    'TL': { label: 'COUNTRY_TL', code: 'TL', callingCode: '670' }, // Timor-Leste
    'TO': { label: 'COUNTRY_TO', code: 'TO', callingCode: '676' }, // Tonga
    'TT': { label: 'COUNTRY_TT', code: 'TT', callingCode: '1868' }, // Trinidad and Tobago
    'TN': { label: 'COUNTRY_TN', code: 'TN', callingCode: '216' }, // Tunisia
    'TR': { label: 'COUNTRY_TR', code: 'TR', callingCode: '90' }, // Turkey
    'TV': { label: 'COUNTRY_TV', code: 'TV', callingCode: '688' }, // Tuvalu
    'TW': { label: 'COUNTRY_TW', code: 'TW', callingCode: '886' }, // Taiwan
    'TZ': { label: 'COUNTRY_TZ', code: 'TZ', callingCode: '255' }, // Tanzania
    'UG': { label: 'COUNTRY_UG', code: 'UG', callingCode: '256' }, // Uganda
    'UA': { label: 'COUNTRY_UA', code: 'UA', callingCode: '380' }, // Ukraine
    'UY': { label: 'COUNTRY_UY', code: 'UY', callingCode: '598' }, // Uruguay
    'US': { label: 'COUNTRY_US', code: 'US', callingCode: '1' }, // United States
    'UZ': { label: 'COUNTRY_UZ', code: 'UZ', callingCode: '998' }, // Uzbekistan
    'VA': { label: 'COUNTRY_VA', code: 'VA', callingCode: '379' }, // Vatican City
    'VC': { label: 'COUNTRY_VC', code: 'VC', callingCode: '1784' }, // Saint Vincent and the Grenadines
    'VE': { label: 'COUNTRY_VE', code: 'VE', callingCode: '58' }, // Venezuela
    'VG': { label: 'COUNTRY_VG', code: 'VG', callingCode: '1284' }, // British Virgin Islands
    'VI': { label: 'COUNTRY_VI', code: 'VI', callingCode: '1340' }, // United States Virgin Islands
    'VN': { label: 'COUNTRY_VN', code: 'VN', callingCode: '84' }, // Vietnam
    'VU': { label: 'COUNTRY_VU', code: 'VU', callingCode: '678' }, // Vanuatu
    'WF': { label: 'COUNTRY_WF', code: 'WF', callingCode: '681' }, // Wallis and Futuna
    'WS': { label: 'COUNTRY_WS', code: 'WS', callingCode: '685' }, // Samoa
    'YE': { label: 'COUNTRY_YE', code: 'YE', callingCode: '967' }, // Yemen
    'ZA': { label: 'COUNTRY_ZA', code: 'ZA', callingCode: '27' }, // South Africa
    'ZM': { label: 'COUNTRY_ZM', code: 'ZM', callingCode: '260' }, // Zambia
    'ZW': { label: 'COUNTRY_ZW', code: 'ZW', callingCode: '263' } // Zimbabwe
};

/**
 * A list of country codes protected by GDPR
 */
export const GDPR_COUNTRY_CODES = [
    // EU Countries
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE',

    // Others (UK + Channel Islands)
    'GB', 'GG', 'JE', 'IM',

    // EEA Countries
    'AD', 'IS', 'LI', 'NO', 'CH',

    // Others (Outside Europe)
    'AR', 'CA', 'FO', 'IL', 'NZ', 'UY', 'JP', 'KR'
];

export default CountriesConstants;
