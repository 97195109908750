import Utils from 'core-helpers/utils.js';

/**
 * Returns the plan duration from a nav item
 * @param {Element} element
 * @return {Number}
 */
const getPlanDuration = (element) => {
    const months = element.getAttribute('data-month-plan');
    return Number(months);
};

/**
 * Returns the relevant cart URL
 * @return {String}
 */
const getCartURL = () => {
    const tieredPlans = Utils.getById('premium-paywall');
    return tieredPlans.getAttribute('data-success');
};

/**
 * @return {Array}
 */
const getNavigationItems = () => {
    const tieredPlansNav = Utils.getById('premium-paywall-nav');
    return Utils.getElementsByClass('js-navglide-item', tieredPlansNav);
};

const PremiumPaywallHelper = {
    getPlanDuration,
    getCartURL,
    getNavigationItems,
};

export default PremiumPaywallHelper;
