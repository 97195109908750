import Utils from 'core-helpers/utils.js';

const ITEM_ROTATOR_INITIALIZED_CLASS = 'js-item-rotator--initialized';
const ITEM_CLASS = 'js-item-rotator__item';
const ITEM_ACTIVE_CLASS = 'js-item-rotator__item--active';
const ITEM_PREV_CLASS = 'js-item-rotator__item--prev';
const ITEM_NEXT_CLASS = 'js-item-rotator__item--next';

/**
 * A class for rotating through elements at a given interval. There is no associated styling
 * so that must be done manually but you can pass the `activeClass`, `prevClass`, `nextClass`
 * which corresponds to the current rotated item.
 *
 * @example
 * const instance = new ItemRotator(element, {
 *     interval: 5000,
 *     initializedClass: 'class-when-initialized',
 *     itemActiveClass: 'class-when-active-item',
 *     itemPrevClass: 'class-when-prev-item',
 *     itemNextClass: 'class-when-next-item',
 * });
 *
 * <div class="js-item-rotator">
 *     <div class="js-item-rotator__item js-item-rotator__item--active">
 *         ...
 *     </div>
 *     <div class="js-item-rotator__item">
 *         ...
 *     </div>
 *     <div class="js-item-rotator__item">
 *         ...
 *     </div>
 * </div>
 */
class ItemRotator {
    /**
     * Initialize an item rotator
     * @param {Element} wrapperElement
     * @param {Object} options
     * @param {Number} options.interval - The interval between transitions
     * @param {string} options.initializedClass - Class to add to the rotator when initialized
     * @param {string} options.itemActiveClass - Class to add to the active item
     * @param {string} options.itemPrevClass - Class to add to the previous item
     * @param {string} options.itemNextClass - Class to add to the next item
     */
    constructor(wrapperElement, options = {}) {
        this.wrapperElement = wrapperElement;
        this.options = options;

        this.interval = null;
        this.currentIndex = 0;
        this.items = [];
        this.classes = {
            initializedClass: this.getItemClass(ITEM_ROTATOR_INITIALIZED_CLASS, options.initializedClass),
            itemPrevClass: this.getItemClass(ITEM_PREV_CLASS, options.itemPrevClass),
            itemActiveClass: this.getItemClass(ITEM_ACTIVE_CLASS, options.itemActiveClass),
            itemNextClass: this.getItemClass(ITEM_NEXT_CLASS, options.itemNextClass),
        };

        if (this.wrapperElement) {
            this.initItemRotator();
        }
    }

    initItemRotator() {
        this.items = Utils.getElementsByClass(ITEM_CLASS, this.wrapperElement);

        if (this.items.length > 1) {
            this.startItemRotation();
        }

        Utils.addClass(this.wrapperElement, this.classes.initializedClass);
    }

    startItemRotation() {
        const onRotateItem = () => {
            this.rotateItemToIndex(this.currentIndex + 1);
        };

        if (this.interval) {
            clearInterval(this.interval);
        }

        this.interval = setInterval(onRotateItem.bind(this), this.options.interval);
    }

    rotateItemToIndex(nextIndex) {
        const prevIndex = this.currentIndex;

        this.currentIndex = this.getActualNextIndex(nextIndex);

        const [oldPrevItem, oldCurrentItem, oldNextItem] = this.calculateIndexItems(prevIndex);
        const [prevItem, currentItem, nextItem] = this.calculateIndexItems(this.currentIndex);

        Utils.removeClass(oldPrevItem, this.classes.itemPrevClass);
        Utils.removeClass(oldCurrentItem, this.classes.itemActiveClass);
        Utils.removeClass(oldNextItem, this.classes.itemNextClass);

        Utils.addClass(prevItem, this.classes.itemPrevClass);
        Utils.addClass(currentItem, this.classes.itemActiveClass);
        Utils.addClass(nextItem, this.classes.itemNextClass);

        this.onChangeItem(prevIndex, this.currentIndex);
    }

    calculateIndexItems(currentIndex) {
        const currentItem = this.items[currentIndex];
        const prevItem = currentIndex > 0 ? this.items[currentIndex - 1] : this.items[this.items.length - 1];
        const nextItem = currentIndex < this.items.length - 1 ? this.items[currentIndex + 1] : this.items[0];

        return [prevItem, currentItem, nextItem];
    }

    /**
     * Given the index to change the rotator to, it checks the following options:
     * - Below 0 index: backwards arrow is pressed repeatedly - resets to the last index
     * - Index is within the item length: return usual index
     * - Over item length index: forwards arrow or rotator has reached the end - reset to first index
     * @param {Number} currentIndex
     * @returns {Number} actual index to switch rotator to
     */
    getActualNextIndex(currentIndex) {
        if (currentIndex < 0) {
            return this.items.length - 1;
        }

        if (currentIndex < this.items.length) {
            return currentIndex;
        }

        return 0;
    }

    getItemClass(jsClass, customClass) {
        const classes = [jsClass];

        if (customClass) {
            classes.push(customClass);
        }

        return classes.join(' ');
    }

    /**
     * Function that is called when changing item
     * @abstract
     * @param {number} prevIndex
     * @param {number} curIndex
     */
    onChangeItem() {}

    /**
     * Set the current item
     * @public
     * @param {number} index
     * @param {Boolean} resetInterval - Reset the interval on change
     */
    changeItem(index, resetInterval) {
        this.rotateItemToIndex(index);

        if (resetInterval) {
            this.startItemRotation();
        }
    }
}

export default ItemRotator;
