import Utils from 'core-helpers/utils.js';

// Vars
let formGroups = null;
const focusClass = 'form-group--focus';

/**
 * Attach focus listener to a given element
 * When triggered this adds particular class to the given group
 * @param {Node} el | element
 * @param {Node} group | group
 */
const attachFocusToElement = (el, group) => {
    el.addEventListener('focus', () => {
        Utils.addClass(group, focusClass);
    });
};

/**
 * Attach blur listener to a given element
 * When triggered this adds particular class to the given group
 * @param {Node} el | element
 * @param {Node} group | group
 */
const attachBlurToElement = (el, group) => {
    el.addEventListener('blur', () => {
        Utils.removeClass(group, focusClass);
    });
};

/**
 * Finds form element inside a given js-formGroup element
 * @param {Node} group | js-formGroup
 * @return {Node || null} form element
 */
const getFormElement = (group) => {
    const element = group.querySelector('input, textarea, select');
    return element || null;
};

/**
 * Loops through all form groups that were found on the page
 * Proceeds to retrieve form element inside this element
 * Then attaches focus & blur event listeners to it
 */
const loopFormGroups = () => {
    formGroups.forEach((group) => {
        const formElement = getFormElement(group);
        if (formElement) {
            attachFocusToElement(formElement, group);
            attachBlurToElement(formElement, group);
        }
    });
};

const init = () => {
    formGroups = Utils.getElementsByClass('js-formGroup');
    loopFormGroups();
};

init();
