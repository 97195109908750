import APIRequestsService from 'endpoint/api-requests-service.js';
import endpoints from 'endpoint/endpoint-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import {decomposeAxiosError, composeAxiosError} from 'endpoint/api-requests-service.helpers.js';
import {ERROR_BAD_RESPONSE_FORMAT, ERROR_PARAMS_MISSING} from 'endpoint/api-requests-service.constants.js';

const B2B_ROLES = [25];

const ERROR_DENY_AUTHENTICATION = {
    applicationCode: 'CURRENT_USER_DENY_AUTHENTICATION',
    message: 'Expected authentication cookies are not present.',
};

/*
 * Private vars
 */
let getDeferred = null;
const filename = 'current-user-service.js';

const logError = (message, data) => {
    ExceptionService.handle('warning', {filename, message, data});
};

/**
 * This methods indicates whether or not a user
 * can be authenticated because all the authentication
 * cookies are present. This attribute is set by PHP in the base Twig.
 */
const canUserBeAuthenticated = () => {
    return document.body.getAttribute('data-user-loggedin') || false;
};

/**
 * Returns the current user course language code.
 * @param {object} user
 * @param {object} params
 * @param {boolean} params.forceEnc - force 'enc' for english
 * @return {string}
 */
const getLangCourseCode = (user, params = {}) => {
    const courseCode = user && user.languages ? user.languages.learning_default : null;

    if (courseCode === 'en' && params.forceEnc) {
        return 'enc';
    }

    return courseCode;
};

/**
 * Returns the current authenticated user data
 * @param {object} params - optional
 * @param {Boolean} params.force -  whether or not to force the request
 * @return {Promise}
 */
const get = (params = {}) => {
    /**
     * Avoid multiple requests at the same time
     * if the call has been invoked already.
     */
    if (getDeferred && !params.force) {
        return getDeferred;
    }

    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`get() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data;
    };

    /**
     *
     * In order to avoid multiple calls to the backend
     * for logged out users or guests users, we only try to
     * invoke the user endpoint if we have all the authentication cookies.
     * ('access-token')
     *
     * As this check is twig and cookie based, we must let the force
     * option to bypass this test. Some APIs (e.g. autoauth flow) requires extra call
     * to the user endpoint afterwards and even if the user wasn't authenticated on
     * page load, we might need to force the call (e.g. autoauth flow).
     *
     */
    if (!canUserBeAuthenticated() && !params.force) {
        getDeferred = onError(composeAxiosError(ERROR_DENY_AUTHENTICATION));
        return getDeferred;
    }

    const url = endpoints.generateEndpoint('currentUser');

    getDeferred = APIRequestsService.get(url).then(onSuccess).catch(onError);

    return getDeferred;
};

/**
 * Updates the current authenticated user
 * with given passed data.
 * @param  {object} params
 * @return {promise}
 */
const update = (params) => {
    const onError = (error = {}) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`update() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data;
    };

    const updateUser = (user) => {
        if (!params) {
            return onError(composeAxiosError(ERROR_PARAMS_MISSING));
        }

        const url = endpoints.generateEndpoint('userUpdate').replace('{uid}', user.uid);

        return APIRequestsService.post(url, params);
    };

    return get().then(updateUser).then(onSuccess).catch(onError);
};

/**
 * Indicates whether or not if
 * the passed user is associated to B2B.
 * Can be Admin, Instructor or Student.
 * @return {Boolean}
 */
const isB2BUser = (user) => {
    if (!user) {
        return false;
    }
    return user.roles.some((role) => {
        return B2B_ROLES.includes(role);
    });
};

/**
 * Returns current user datas
 */
const CurrentUserService = {
    get,
    update,
    getLangCourseCode,
    isB2BUser,
};

export default CurrentUserService;
