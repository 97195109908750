var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ExceptionService from 'core-services/exceptions/exception-service.js';
import { ENDPOINTS, getEndpoint } from '@busuu/endpoints';
import { fetchFreeTrialEligiblity } from '@busuu/pricing';
import { isEligibleForReferralFreeTrial } from '@busuu/user';
// Services
import CurrentUserService from 'user/current-user-service.js';
// Private vars
const FILENAME = 'free-trial.service.ts';
/**
 * Logs an error and optionally to Sentry with type: 'error'
 * @param {string} message
 * @param {object} error
 * @param {string} type
 */
const logError = (message, error, type = 'warning') => {
    ExceptionService.handle(type, {
        filename: FILENAME,
        message,
        data: error,
        sendToSentry: !!(type === 'error'),
    });
};
let isEligibleForFreeTrial = null;
export const getFreeTrialEligibility = () => __awaiter(void 0, void 0, void 0, function* () {
    if (isEligibleForFreeTrial !== null) {
        return isEligibleForFreeTrial;
    }
    const user = (yield CurrentUserService.get());
    if (!user) {
        throw new Error('User is not authenticated');
    }
    const endpoint = getEndpoint({
        baseUrl: process.env.baseAPI,
        endpoint: ENDPOINTS.SUBSCRIPTION_FT_ELIGIBILITY,
        replacements: { '{userUuid}': user.uuid },
    });
    try {
        const { isCustomerEligibleForFreeTrial } = yield fetchFreeTrialEligiblity(endpoint);
        isEligibleForFreeTrial = isCustomerEligibleForFreeTrial;
        return isCustomerEligibleForFreeTrial;
    }
    catch (err) {
        return false;
    }
});
export const userIsEligibleForReferralFreeTrial = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const user = (yield CurrentUserService.get());
        if (!user) {
            throw new Error('User is not authenticated');
        }
        const isEligibleForFreeTrial = yield getFreeTrialEligibility();
        const isEligibleForReferralFT = isEligibleForReferralFreeTrial(user, isEligibleForFreeTrial);
        return isEligibleForReferralFT;
    }
    catch (err) {
        const message = 'userIsEligibleForReferralFreeTrial() - An error occurred';
        logError(message, err);
        throw err;
    }
});
