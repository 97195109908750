import APIRequestsService from 'endpoint/api-requests-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import endpoints from 'endpoint/endpoint-service.js';
import {decomposeAxiosError} from 'endpoint/api-requests-service.helpers.js';

const filename = 'voucher-service.js';

const logError = (message, data) => {
    ExceptionService.handle('warning', {filename, message, data});
};

const send = (voucher) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`send() error: ${applicationCode} - ${message}`, error);

        throw {
            type: applicationCode,
            message,
        };
    };

    const url = endpoints.generateEndpoint('voucherRedemption');

    return APIRequestsService.post(url, {code: voucher}).catch(onError);
};

const VoucherService = {
    send,
};

export default VoucherService;
