// Services
import endpoints from 'endpoint/endpoint-service.js';
import APIRequestsService from 'endpoint/api-requests-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import ConfigService from 'config/config-service.js';

// Helpers
import {decomposeAxiosError, composeAxiosError} from 'endpoint/api-requests-service.helpers.js';
import intersection from 'lodash/intersection';

// Constants
import {ERROR_BAD_RESPONSE_FORMAT} from 'endpoint/api-requests-service.constants.js';
import {COURSES_LANGS_BY_INTERFACE_LANGS, COUNTRY_TO_SHOW_US_FLAG} from 'course-config/course-config-constants.js';

const FILENAME = 'course-config-service.js';
let availableCourseLanguagesDefer = null;

const logError = (message, data) => {
    ExceptionService.handle('warning', {FILENAME, message, data});
};

/**
 * Updates the local availableCourseLanguagesDefer object
 * that is stored in this service
 * This prevents us from having to reload
 * again after we've uddated something in our settings
 * @param {Object} Object
 */
const updateAvailableCourseLangLocally = (languages) => {
    availableCourseLanguagesDefer = languages;
};

const getInterfaceLanguage = () => {
    return window.BUSUU_GLOBALS.LANG_INTERFACE || 'en';
};

const getCourseLangsByInterfaceLangs = async (interfaceLang) => {
    const countryCode = await ConfigService.getCountryCode().catch(() => {
        return null;
    });

    if (countryCode === COUNTRY_TO_SHOW_US_FLAG && interfaceLang === 'en') {
        return COURSES_LANGS_BY_INTERFACE_LANGS.us || null;
    }

    return COURSES_LANGS_BY_INTERFACE_LANGS[interfaceLang] || null;
};

/**
 * Call /course-config endpoint to get a list of available course languages
 * @return {Promise}
 */
const getAvailableCourseLanguages = () => {
    if (availableCourseLanguagesDefer) {
        return availableCourseLanguagesDefer;
    }

    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`getAvailableCourseLanguages() error: ${applicationCode} - ${message}`, error);

        throw {
            type: applicationCode,
            message,
        };
    };

    const onSuccess = async (response) => {
        if (!response || !response.data || !response.data.available_languages) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        try {
            const interfaceLang = getInterfaceLanguage();

            const availableCourseLanguages = response.data.available_languages.reduce((seed, courseLang) => {
                const {name, available_course_packs: availableCoursePacks} = courseLang;

                availableCoursePacks.forEach((pack) => {
                    if (pack.available_interface_languages.includes(interfaceLang)) {
                        seed.push(name);
                    }

                    return seed;
                });

                // Removes duplicated interface languages
                return [...new Set(seed)];
            }, []);

            // Marketing dictates certain order of courses given their popularity
            const marketingLangsReorder = await getCourseLangsByInterfaceLangs(interfaceLang);

            /**
             * Based on whether marketing has dictated a reordering of courses, intersect both arrays where:
             * - If marketing has reordered the courses - this takes precedence over what backend tells us
             * - If marketing has not reordered the courses - use the courses that backend returns by default
             */
            return marketingLangsReorder
                ? intersection(marketingLangsReorder, availableCourseLanguages)
                : availableCourseLanguages;
        } catch (error) {
            logError('getAvailableCourseLanguages() error:', error);
            throw error;
        }
    };

    const url = endpoints.generateEndpoint('courseConfig');

    availableCourseLanguagesDefer = APIRequestsService.get(url).then(onSuccess).catch(onError);

    return availableCourseLanguagesDefer;
};

/**
 * TODO: To be unified across projects (WebApp uses this too)
 * Create a `shared-js` languages helper and methods
 */
const CourseConfigService = {
    getAvailableCourseLanguages,
    test: {updateAvailableCourseLangLocally},
};

export default CourseConfigService;
