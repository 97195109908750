import Utils from 'core-helpers/utils.js';
import Tracking from 'tracking/tracking-controller.ts';
import CurrentUser from 'user/current-user-service.js';
import {retrieveEcommerceOriginValue} from '@busuu/marketing';
/**
 * Private vars
 */
let user = null;

const isTrialAccess = () => {
    const pageIdentifier = document.body.getAttribute('data-page-identifier');
    return Boolean(pageIdentifier === 'claim-trial');
};

const getCurrentLanguage = () => {
    return window.BUSUU_GLOBALS.LANG_INTERFACE || 'en';
};

const sendTracking = (key, data) => {
    Tracking.send(key, data);
};

const sendEvent = () => {
    const data = {
        paywall_locale: getCurrentLanguage(),
        free_trial: true,
        paywall_type: 'trial',
    };

    if (user) {
        data.paywall_country_code = user.country_code;
        data.paywall_cohort = `${user.country_code}_normal`;
    }

    const origin = retrieveEcommerceOriginValue();
    if (origin) {
        data.ecommerce_origin = origin;
    }

    sendTracking('PAGE_VIEW_PREMIUM', data);
};

const getUser = () => {
    const onUserSuccess = (userResponse) => {
        user = Utils.deepClone(userResponse);
    };
    return CurrentUser.get().then(onUserSuccess);
};

const init = () => {
    if (!isTrialAccess()) {
        return false;
    }

    /**
     * If user is available, add extra datas, if not it means user is not recognize
     * and we will send the minimum datas we can find.
     */
    getUser()
        .catch(() => {})
        .then(sendEvent);
};

init();
