var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Utils from 'core-helpers/utils.js';
import TranslationsService from 'common/translations.service';
import ConfigService from 'config/config-service.js';
import { COUNTRY_TO_SHOW_US_FLAG } from 'course-config/course-config-constants.js';
import { DYNAMIC_FLAGS_READY_EVENT } from 'components/flags-list-constants.js';
import PubSub from 'pubsub-js';
const GENERIC_HIDDEN_CLASS = 'hidden';
/**
 * Class providing a dynamic flags list
 * Responsible for fetching available languages and toggling the loader once ready
 */
export default class FlagsListClass {
    constructor(args) {
        this.initFlagsList = this.initFlagsList.bind(this);
        this.createFlagElement = this.createFlagElement.bind(this);
        this.hideElement = this.hideElement.bind(this);
        this.showElement = this.showElement.bind(this);
        void this.initFlagsList(args);
    }
    hideElement(elem) {
        Utils.addClass(elem, GENERIC_HIDDEN_CLASS);
    }
    showElement(elem) {
        Utils.removeClass(elem, GENERIC_HIDDEN_CLASS);
        PubSub.publish(DYNAMIC_FLAGS_READY_EVENT);
    }
    createFlagElement(lang, baseRegisterUrl, countryCode) {
        const registerUrl = baseRegisterUrl.replace('{LANGUAGE_CODE}', lang);
        const langTrs = TranslationsService.getTranslation(`LANGUAGE_${lang}`);
        const flagLang = countryCode === COUNTRY_TO_SHOW_US_FLAG && lang === 'en' ? 'us' : lang;
        const flagSrc = `${process.env.staticRoot}/img/icons/flags/icon_flag-${flagLang}.svg`;
        const chevronSrc = `${process.env.staticRoot}/img/icons/icon_chevron-quaternary.svg`;
        return `<a href="${registerUrl}" class="flag-text js-flag" data-value="${lang}">
                <span class="icon icon--circle flag-text__icon">
                    <img src="${flagSrc}" alt="${langTrs}"/>
                </span>
                <p class="flag-text__text">${langTrs}</p>
                <span class="icon flag-text__chevron icon--quaternary-base">
                    <img src="${chevronSrc}" />
                </span>
            </a>`;
    }
    initFlagsList({ languages, loader, container }) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseRegisterUrl = container.getAttribute('data-register-url');
            const countryCode = (yield ConfigService.getCountryCode().catch(() => {
                return null;
            }));
            languages.forEach((lang) => {
                const flagElem = this.createFlagElement(lang, baseRegisterUrl, countryCode);
                container.insertAdjacentHTML('beforeend', flagElem);
            });
            setTimeout(() => {
                this.hideElement(loader);
                this.showElement(container);
            }, 500);
        });
    }
}
