import ConsoleHelper from 'core-helpers/console-helper.js';

// Private vars
const filename = 'dom-utils.service.js';

/**
 * Returns a DOM element that can be appended anywhere
 *
 * NB: The styles should be a string so we don't need to
 * add every single style individually.
 *
 * Usage:
 *     createDOMElement({
 *         type: 'span',
 *         class: 'some-class',
 *         id: 'some-id'
 *         styles: 'padding:2px;line-height:32px;'
 *         text: 'Some inner text'
 *     });
 *
 * @param  {Object} params
 * @return {Element}
 */
const createDOMElement = (params) => {

    if (!params.type) {
        return ConsoleHelper.log(filename, 'createDOMElement() - missing element type.');
    }

    const element = document.createElement(params.type);

    if (params.class) {
        element.className = params.class;
    }

    if (params.id) {
        element.id = params.id;
    }

    if (params.styles) {
        element.style.cssText = params.styles;
    }

    if (params.text) {
        element.innerText = params.text;
    }

    return element;
};

/**
 * Returns a string or an object of css properties from a given element.
 *
 * Usage:
 * retrieveElementStyles('string', el, ['padding-left']);
 *
 * @param  {String} type | type of return we want - can be string or object
 * @param  {Element} element | element we want to target
 * @param  {Array} styles | list of styles ie ['padding-top', 'padding-left']
 * @return {String|Object}
 */
const retrieveElementStyles = (type = 'string', element, styles) => {

    if (!element || !styles) {
        return ConsoleHelper.log(filename, 'retrieveElementStyles() - missing `element` or `styles` parameter.');
    }

    let styleString = '';
    let styleObject = {};
    const styleMap = window.getComputedStyle(element);

    styles.forEach((value) => {
        const propertyValue = styleMap.getPropertyValue(value);
        styleString += `${value}:${propertyValue};`;
        styleObject[value] = propertyValue;
    });

    if (type === 'object') {
        return styleObject;
    }

    return styleString;
};

export default {
    createDOMElement,
    retrieveElementStyles
};
