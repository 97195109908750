import Utils from 'core-helpers/utils.js';
import {LEFT, RIGHT} from 'components/flags-carousel/flags-carousel-constants.js';

const ENABLE_CAROUSEL_CLASS = 'choose-language-scrollable__carousel--enabled';
const DISABLE_BUTTON_CLASS = 'choose-language-scrollable__carousel-arrow--disabled';
const FLAG_CARD_CLASS = 'flag-text';

class FlagsCarousel {
    constructor(wrapperElement, options = {}) {
        this.wrapperElement = wrapperElement;
        this.options = options;

        if (this.wrapperElement) {
            this.initCarousel();
            this.initInteractionEvents();
        }
    }

    initCarousel() {
        Utils.addClass(this.wrapperElement, ENABLE_CAROUSEL_CLASS);
    }

    getNewScrollValue() {
        const {container} = this.options;

        const cardWidth = Utils.getElementByClass(FLAG_CARD_CLASS).clientWidth;
        const increment = (Math.floor(container.clientWidth / cardWidth) - 1) * cardWidth;
        return increment;
    }

    checkButtonsState(value) {
        const {content, leftArrow, rightArrow} = this.options;
        const maxScrollLeft = content.scrollWidth - content.clientWidth;

        if (value <= 0) {
            this.disableButton(leftArrow);
        }

        if (value >= maxScrollLeft) {
            this.disableButton(rightArrow);
        }
    }

    scrollTo(value) {
        const {content} = this.options;
        content.scroll({
            left: value,
        });
    }

    disableButton(button) {
        Utils.addClass(button, DISABLE_BUTTON_CLASS);
    }

    enableButton(button) {
        Utils.removeClass(button, DISABLE_BUTTON_CLASS);
    }

    clickButton(button) {
        const {content, leftArrow, rightArrow} = this.options;
        let newValue;

        switch (button) {
            case LEFT:
                this.enableButton(rightArrow);
                newValue = content.scrollLeft - this.getNewScrollValue();
                break;
            case RIGHT:
            default:
                this.enableButton(leftArrow);
                newValue = content.scrollLeft + this.getNewScrollValue();
                break;
        }

        this.scrollTo(newValue);
        this.checkButtonsState(newValue);
    }

    initInteractionEvents() {
        const {leftArrow, rightArrow} = this.options;

        if (leftArrow) {
            leftArrow.addEventListener('click', this.clickButton.bind(this, LEFT));
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', this.clickButton.bind(this, RIGHT));
        }

        this.disableButton(leftArrow);
    }
}

export default FlagsCarousel;
